import * as React from 'react';
import { useEffect } from 'react';
import Menu from '@mui/material/Menu';
import { NestedMenuItem } from 'mui-nested-menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Divider, ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material';
import { useState } from 'react';
import StarSvg from '../../img/StarSvg';
import NotificationSvg from '../../img/NotificationSvg';
import { useTranslation } from 'react-i18next';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import DriverSvg from '../../img/DriverSvg';
import ArchiveSvg from '../../img/ArchiveSvg';
import PersonSvg from '../../img/PersonSvg';
import ConfirmDialog from '../dialog/ConfirmDialog';
import { useOutletContext } from 'react-router-dom';
import { useGetLogbooksWithOpenTours } from '../../api/LogbookApi';
import { useRef } from 'react';
import AdminCheckAndLogoutDriver from './AdminCheckAndLogoutDriver';

export default function AdminTableToolBarMenu(props) {
    const {
        setOpenEditReasonDialog,
        setOpenImportReasonDialog,
        updateLogbookPrivateJourney,
        updateLogbookArchiveStatus,
        setOpenDriversDialog,
        setOpenNotificationDialog,
        setOpenAccessDialog,
        selected,
        setMessage,
        logbooks,
        setSelected,
    } = props;

    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [openArchivDialog, setOpenArchivDialog] = useState(false);
    const isArchivedLogbookSelected = useOutletContext().logbookList.some((logbook) => selected.includes(logbook.value) && logbook.status === 'archived');
    const [disableToolbar, setDisableToolbar] = useState(false);
    const { logbooksWithOpenTours } = useGetLogbooksWithOpenTours();
    const logbooksWithOpenToursList = useRef([]);
    const [driverStatus, setDriverStatus] = useState({
        startProcess: false,
        idLogbook: null,
        idAsset: null,
        isPrivatModusActive: null,
    });

    useEffect(() => {
        setDisableToolbar(isArchivedLogbookSelected);
        // eslint-disable-next-line
    }, [selected]);

    const handleClick = (event) => setAnchorEl(event.currentTarget);

    const handleClose = () => setAnchorEl(null);

    const menuItemStyle = {
        display: 'flex',
        color: '#212121',
        fontSize: '14px',
        fontFamily: 'CircularProMedium',
        alignItems: 'center',
        height: '100%',
    };
    const handleOpenDialog = (setState, setAnchorEl) => {
        setState(true);
        setAnchorEl(null);
    };

    const checkSelectedLogbooks = async () => {
        const openToursResult = await logbooksWithOpenTours(selected);
        if (openToursResult === null) {
            setMessage(t('checkingLogbooksError'));
        } else if (openToursResult.length === 0) {
            logbooksWithOpenToursList.current = [];
            setOpenArchivDialog(true);
        } else if (openToursResult.length > 0) {
            const filteredLogbooks = logbooks.filter((logbook) => openToursResult.includes(logbook.id));
            logbooksWithOpenToursList.current = filteredLogbooks.map((logbook) => {
                return logbook.name;
            });
            setOpenArchivDialog(true);
        }
    };

    const prepareDriverStatus = () => {
        if (selected.length > 1) {
            setMessage(t('toManyLogbooks'));
        } else {
            const result = logbooks.filter((logbook) => logbook.id === selected[0]);
            setDriverStatus({
                startProcess: true,
                idLogbook: result[0].id,
                idAsset: result[0].idAsset,
                isPrivatModusActive: result[0].privateSwitch === t('activated'),
            });
        }
    };

    return (
        <>
            <Box
                onClick={(event) => handleClick(event)}
                sx={{
                    height: '40px',
                    width: '40px',
                    borderRadius: '20px',
                    '&:hover': {
                        backgroundColor: '#EEEEEE',
                    },
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: '8px',
                    cursor: 'pointer',
                    pointerEvents: disableToolbar ? 'none' : 'auto',
                    opacity: disableToolbar ? 0.5 : 1.0,
                }}
            >
                <MoreVertIcon />
            </Box>
            <Menu anchorEl={anchorEl} open={open} onClose={() => handleClose()}>
                {/**Driver menu */}
                <MenuItem onClick={() => handleOpenDialog(setOpenDriversDialog, setAnchorEl)}>
                    <ListItemIcon>
                        <DriverSvg />
                    </ListItemIcon>
                    <ListItemText primary={<Typography sx={menuItemStyle}>{t('drivers')}</Typography>} />
                </MenuItem>
                {/**Access menu */}
                <MenuItem onClick={() => handleOpenDialog(setOpenAccessDialog, setAnchorEl)}>
                    <ListItemIcon>
                        <PersonSvg />
                    </ListItemIcon>
                    <ListItemText primary={<Typography sx={menuItemStyle}>{t('access')}</Typography>} />
                </MenuItem>
                {/* <AdminAccessDialog selectedLogbooks={selected} setSelectedLogooks={setSelected} teamId={teamId} setMessage={setMessage} /> */}
                {/**Reasons menu */}
                <NestedMenuItem
                    sx={{
                        paddingLeft: 2,
                        svg: {
                            alignSelf: 'center',
                        },
                    }}
                    leftIcon={<StarSvg />}
                    rightIcon={<ArrowRightIcon color='info' />}
                    label={
                        <Typography component='span' sx={{ marginLeft: '4px', ...menuItemStyle }}>
                            {t('reasons')}
                        </Typography>
                    }
                    parentMenuOpen={open}
                >
                    <MenuItem
                        onClose={() => handleClose()}
                        onClick={() => {
                            handleClose();
                            setOpenEditReasonDialog(true);
                        }}
                        sx={{ color: '#212121', fontSize: '14px', fontFamily: 'CircularProMedium' }}
                    >
                        {t('edit')}
                    </MenuItem>
                    <MenuItem
                        onClose={() => handleClose()}
                        onClick={() => {
                            handleClose();
                            setOpenImportReasonDialog(true);
                        }}
                        sx={{ color: '#212121', fontSize: '14px', fontFamily: 'CircularProMedium' }}
                    >
                        {t('import')}
                    </MenuItem>
                </NestedMenuItem>
                {/**Notification menu */}
                <MenuItem onClick={() => handleOpenDialog(setOpenNotificationDialog, setAnchorEl)}>
                    <ListItemIcon>
                        <NotificationSvg />
                    </ListItemIcon>
                    <ListItemText primary={<Typography sx={menuItemStyle}>{t('notifications')}</Typography>} />
                </MenuItem>
                <Divider />
                {/**Logout driver menu */}
                <MenuItem
                    onClick={() => {
                        handleClose();
                        prepareDriverStatus();
                    }}
                >
                    <ListItemText
                        primary={
                            <Typography sx={menuItemStyle}>
                                {t('drivers')} {String(t('logout')).toLocaleLowerCase()}
                            </Typography>
                        }
                    />
                </MenuItem>
                {/**Private switch menu */}
                <NestedMenuItem
                    sx={{
                        paddingLeft: 1,
                    }}
                    rightIcon={<ArrowRightIcon color='info' />}
                    label={
                        <Typography component='span' sx={menuItemStyle}>
                            {t('privateSwitch')} {t('status')}
                        </Typography>
                    }
                    parentMenuOpen={open}
                    onKeyDown={(e) => e.preventDefault()}
                    onKeyUp={(e) => e.preventDefault()}
                >
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            updateLogbookPrivateJourney(true);
                        }}
                        sx={{ color: '#212121', fontSize: '14px', fontFamily: 'CircularProMedium', textTransform: 'capitalize' }}
                    >
                        {t('active')}
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            updateLogbookPrivateJourney(false);
                        }}
                        sx={{ color: '#212121', fontSize: '14px', fontFamily: 'CircularProMedium' }}
                    >
                        {t('deactivated')}
                    </MenuItem>
                </NestedMenuItem>
                {/**Archive menu */}
                <MenuItem
                    onClick={() => {
                        handleClose();
                        checkSelectedLogbooks();
                    }}
                >
                    <ListItemIcon>
                        <ArchiveSvg />
                    </ListItemIcon>
                    <ListItemText primary={<Typography sx={menuItemStyle}>{t('archive')}</Typography>} />
                </MenuItem>
            </Menu>
            <ConfirmDialog
                open={openArchivDialog}
                setOpen={setOpenArchivDialog}
                handleConfirm={updateLogbookArchiveStatus}
                dialogContentText={logbooksWithOpenToursList.current.length === 0 ? t('confirmArchiv') : `${t('openToursFound')}${logbooksWithOpenToursList.current.join(', ')}`}
                confirmButtonText={t('archive')}
                disabled={logbooksWithOpenToursList.current.length > 0}
            ></ConfirmDialog>
            {driverStatus.startProcess && (
                <AdminCheckAndLogoutDriver
                    updateLogbookPrivateJourney={updateLogbookPrivateJourney}
                    idLogbook={driverStatus.idLogbook}
                    idAsset={driverStatus.idAsset}
                    isPrivatModusActive={driverStatus.isPrivatModusActive}
                    setMessage={setMessage}
                    setSelected={setSelected}
                />
            )}
        </>
    );
}
