import * as React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Moment from 'moment';
import { useGetAllTourVersionsApi, useGetPersonsApi } from '../../api/LogbookApi';
import LinearProgress from '@mui/material/LinearProgress';
import { Button, Card, CardContent, ListItemText } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFullname } from '../other/ConfigBasic';
import theme from '../../themes/theme';

export default function ChangeHistory(props) {
    const selectedTour = props.selectedTour;
    const { t } = useTranslation();
    const [numberOfEntries, setNumberOfEntries] = useState(3);
    const navigate = useNavigate();
    const counter = 5;

    const allTourVersions = useGetAllTourVersionsApi(selectedTour.idLogbook, selectedTour.dtBegin);
    const personsData = useGetPersonsApi();

    if (!allTourVersions || !personsData) {
        return <LinearProgress />;
    }

    const getFullnameById = (personId) => {
        const person = personsData.find((person) => person.id === personId);
        return getFullname(person);
    };

    const getLink = (tour, index) => {
        if (index < numberOfEntries) {
            return (
                <Link onClick={() => navigate(`/edit/${tour.id}`)} underline='none' key={tour.id} sx={{ fontWeight: '400', textTransform: 'none' }}>
                    <ListItem
                        disableGutters
                        sx={[
                            selectedTour.id === tour.id
                                ? {
                                      backgroundColor: theme.palette.success.main,
                                      ':hover': {
                                          backgroundColor: theme.palette.grey[100],
                                      },
                                  }
                                : {
                                      ':hover': {
                                          backgroundColor: theme.palette.grey[100],
                                      },
                                  },
                            { padding: '8px 16px' },
                        ]}
                    >
                        {index === 0 ? <CheckCircleIcon color='primary' sx={{ mr: 1 }} /> : <CancelIcon color='error' sx={{ mr: 1 }} />}
                        <ListItemText disableTypography>{Moment(tour.dtEdited).format('DD.MM.yyyy HH:mm')}</ListItemText>
                        <ListItemText disableTypography sx={{ textAlign: 'right' }}>
                            {getFullnameById(tour.idEditor)}
                        </ListItemText>
                    </ListItem>
                </Link>
            );
        }
    };

    const handleClick = () => {
        const newNumberOfEntries = numberOfEntries + counter;
        setNumberOfEntries(newNumberOfEntries);
    };

    return (
        <Card variant='outlined'>
            <CardContent sx={{ padding: '16px 0px' }}>
                <Grid container xs={12}>
                    <Grid xs={12} padding='4px 16px'>
                        <h4>{t('changeHistory')}</h4>
                    </Grid>
                    <List sx={{ width: '100%' }}>{allTourVersions.map(getLink)}</List>
                    <Grid xs={12} textAlign='center'>
                        <Button variant='text' color='secondary' onClick={handleClick}>
                            {t('showMore')}
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
