export const languages_en = {
    access: 'Accesses',
    accessInfo: 'Only users created in the login with a selected logbook license can be granted access',
    accessChangedSuccessfully: 'Access changed successfully',
    accessEnd: 'End of access',
    accessStart: 'Start of access',
    activate: 'Activate',
    activated: 'activated',
    active: 'Active',
    add: 'Add',
    addAnotherCostType: 'Add another cost type',
    addAPicture: 'Add a picture.',
    addCostCentre: 'Add cost centre',
    addCostObject: 'Add cost object',
    addExpiryDate: 'Add expiry date',
    addressFehler: 'Address must be entered',
    addressPlaceholder: 'Street HauseNo, ZIP city',
    addressSearch: 'address ',
    addUser: 'Add user',
    admin: 'Administrator',
    adminIsRequired: 'An administrator is required',
    administration: 'Overview',
    addADriver: 'CREATE NEW CONTACT',
    addAssets: 'CREATE NEW ASSET',
    all: 'All',
    allUsersAdded: 'All users added',
    annotation: 'Annotation',
    annualOvervirw: 'Annual overview',
    apiErrorDriverData: 'Error while retrieving the driver data:',
    apply: 'Apply',
    arrivalTime: 'Arrival time',
    atThisPosition: 'At this position',
    Autogas: 'LPG (Liquefied Petroleum Gas)',
    back: 'Back',
    bannerExportetTour: 'This tour has already been exported and cannot be changed again.',
    bannerPrivateTour: 'A tour categorized as a private trip can no longer be edited.',
    bannerPrivateTypeEdit: 'Edit the type is no longer possible due to the 7-day rule.',
    baseData: 'Base data',
    Benzin: 'Gasoline',
    business: 'Business trip',
    byNone: '-',
    bySystem: 'by GPS',
    byUser: 'by user',
    calibrateOdometer: 'Calibrate mileage',
    calibrationSuccessful: 'Calibration successful',
    cancel: 'Cancel',
    changed: 'Changed',
    changeHistory: 'Change history',
    changeLogbookDrivers: 'Drivers changed successfully',
    changeLogbookName: 'Name changed successfully',
    checkingLogbooksError: 'Errors when checking the logbooks.',
    chooseVehicle: 'Choose Vehicle',
    chooseRole: 'Select role',
    chosseAssets: 'For which vehicles would you like to create logbooks?',
    chosseDrivers: 'Which persons should be selectable as drivers in the logbooks?',
    renameLogbook: 'Name the logbook',
    chooseVehicleForLogbook: 'Choose a vehicle for your logbook.',
    cleaning_services: 'Cleaning services',
    commute: 'Commute',
    companies: 'Companies',
    compareMileage: 'Compare mileage',
    confirmArchiv: 'Really archive the logbook? This logbook will then no longer record any tours!',
    confirm: 'Confirm',
    confirmDelete: 'Are you sure you want to delete the Receipt?',
    confirmPrivateSwitch: 'If the Private Switch is active, all tours are saved as a private journey until it is deactivated again.',
    connect: 'Connect',
    contacts: 'Contacts',
    costCentre: 'Cost centre',
    costEntries: 'Cost Entries',
    costObject: 'Cost object',
    costs: 'Costs',
    costType: 'Cost type',
    costStepIntro: 'Create and manage costs',
    create: 'Create',
    createdOn: 'Created on',
    createNewLogbook: 'Create a new logbook',
    createReason: 'Create a reason',
    creator: 'Creator',
    currentMileageInKm: 'Current mileage in km',
    currentMileageVehicle: 'Current mileage of the vehicle.',
    custNo: 'Cust. No.',
    completeLBInfo: 'Complete logbook information',
    dataManagement: 'Data management',
    dataRequestInProgress: 'Data request in progress',
    dataRetrievalFailed: 'Data retrieval failed',
    date: 'Date /Time',
    dateShort: 'Date',
    deactivate: 'Deactivate',
    deactivated: 'Deactivated',
    delete: 'Delete',
    deleteEntry: 'Delete entry',
    Diesel: 'Diesel',
    disconnect: 'Disconnect',
    distance: 'Distance',
    done: 'Done',
    dragFileHere: 'Drag document here',
    driver: 'Driver',
    drivers: 'Drivers',
    driverForPayroll: 'A driver must be specified for payroll.',
    driverLoginInfo: 'Successfully logged in.',
    driverLogout: 'Confirm logout',
    driverLogoutInfo: 'Successfully logged out.',
    driverLogoutInfoExtension: 'Successfully logged out - private switch deactivated.',
    driverOverview: 'Driver overview',
    edit: 'Edit',
    editCostCentre: 'Edit cost centre',
    editCostObject: 'Edit cost object',
    edited: 'Edited',
    enterAddress: 'Enter address',
    editedOn: 'Edited',
    editedTours: 'Edited tours',
    editExportContant: 'EDIT CONTENT',
    editor: 'Editor',
    editReasons: 'Edit reasons',
    emailNotifications: 'E-Mail notifications',
    emailNotificationsInfo: 'These users will receive email notifications.',
    end: 'End',
    Erdgas: 'Natural gas',
    errorDuringImport: 'Error during import',
    errorNoPermissionNoLogbook: 'You do not have the necessary rights to create a logbook and no logbook is assigned to you.',
    errorOnATour: 'Error on a tour.',
    errorOnTours: 'Error on tours.',
    errorPrivatTypeChange: 'Only private tour possible',
    errorsUpdateMultipleLogbooks: 'Error when updating {{number}} logbooks!',
    errorUpdateMultipleLogbooks: 'Error when updating {{number}} logbook!',
    export: 'Export',
    enterCurrentMileage: 'Enter current mileage',
    exported: 'exported',
    exportErrorMessage: 'There are one or more unedited tours in this time range. Please edit these tours before export.',
    exportNotPossible: 'Export not possible',
    fee: 'Amount',
    feedback: 'Feedback',
    fehlerImage: 'The image was not uploaded successfully.',
    friday: 'Friday',
    from: 'From',
    fuel: 'Fuel',
    FUEL_ADBLUE: 'AdBlue',
    FUEL_CNG: 'Natural gas',
    FUEL_DIESEL: 'Diesel',
    FUEL_ELECTRICITY: 'Electricity',
    FUEL_GAS: 'Benzin',
    FUEL_H2: 'Hydrogen',
    FUEL_LPG: 'LPG',
    fuelLevel: 'Fuel level',
    furtherInformation: 'Further information',
    general: 'General',
    geolocationInfosNotAvailable: 'Location information is not available.',
    geolocationNotSupported: 'Geolocation is not supported by this browser.',
    geolocationPermissionDenied: 'User denied the request for Geolocation.',
    geolocationTimeOut: 'The request to get user location timed out.',
    geolocationUnknownError: 'An unknown error occurred.',
    gross: 'Gross',
    grossAmount: 'Gross amount',
    guideText: 'Now let us guide you through the most important functions.',
    imageUpdate: 'Image was not updated',
    import: 'Import',
    infoCalibrateOdometer: 'Mileage exported from the GPS box.',
    infoPlaceholderMap: 'Here you will see your journey in the future and you can select stopovers directly on the map.',
    inspection: 'Inspection',
    introStepFive: 'Share the logbook with other users, compare mileage, create reasons, select drivers and more.',
    introStepFour: 'Add costs and receipts for various categories such as fuel, cleaning services, etc.',
    introStepOne: 'Create a new logbook or select an existing one.',
    introStepOneName: 'Select or create a logbook.',
    introStepThree: 'View edited trips or export the logbook.',
    introStepTwo: 'Here you will find all open trips that still need to be edited.',
    infoChangeAddress: 'You can select an address within a maximum radius of 5 km and a maximum of 10 minutes for the time.',
    introStepTwoMobile: 'Here you will find all open trips that still need to be edited. With swipe gestures, tours can be marked directly as “Commute” or “Private”.',
    invalidSelection: 'Invalid selection',
    kg: 'Kilogram',
    kgPrice: 'Price per kilogram',
    kwh: 'kilowatt hour',
    lastCalibration: 'Last calibration',
    lastPosition: 'Last position',
    leasing: 'Leasing',
    leaveOverview: 'Leave overview',
    legalDocuments: 'Legal documents',
    legalNotes: 'Legal notes',
    letsgo: "Let's go",
    licensePlate: 'License plate',
    literprice: 'liter price',
    logbook: 'Logbook',
    logbooksInfo: 'Incomplete logbooks are created without mileage. If logbooks are not named, the vehicle name is adopted.',
    logbookExport: 'Logbook Export',
    logbookListInfo: 'Complete list, see overview',
    logbooks: 'Logbooks',
    logbookSuccessfulCreated: 'Logbook created successfully',
    logout: 'Logout',
    maillingListUpdated: 'Mailing list updated',
    makeStopover: 'Make a stopover',
    mapError: 'Tour is not fully registered',
    mandatoryField: 'Mandatory field',
    markAsCommute: 'Mark as commute',
    markAsPrivatTour: 'Mark as private tour',
    mergeAsPrivateTour: 'Merge as private',
    mergedAsPrivate: 'Will be merged as private trip',
    mergeTours: 'Merge tours',
    mergeToursAsPrivate: 'Merged tours are automatically marked as private tours. This cannot be reversed.',
    mileage: 'Mileage',
    mileageAtStart: 'Mileage at start',
    mileageAtEnd: 'Mileage on arrival',
    mileageBegin: 'Start mileage',
    mileageCalibrated: 'Mileage calibrated',
    mileageEnd: 'End mileage',
    mileageMustBeCompared: 'Mileage must be compared',
    missingPermissionEdit: 'No right to edit',
    missingPermissionsCreateLogbook: 'You do not have enough rights to create a logbook',
    mixedTour: 'Mixed tour',
    modeActivated: 'Mode activated',
    modeDisabled: 'Mode disabled',
    monday: 'Monday',
    moreThen5Km: 'Address must not deviate by more than 5 km.',
    moreThan5KmInputError: 'Deviation over 5 km',
    monthandYear: 'Month and year',
    name: 'Name',
    nameOfLogbook: 'What should the log book be called?',
    net: 'Net',
    netFee: 'Total costs, net',
    newEntry: 'NEW ENTRY',
    newLogbook: 'New logbook',
    newReceiptCreated: 'New cost entry was created.',
    newReceipt: 'New receipt',
    newLogBooks: 'New logbooks',
    next: 'Next',
    noAssetAccess: 'You do not have the necessary rights to use this function. You need access to the vehicle in the "Administration" application.',
    noAssetEditAccess: 'You do not have the necessary rights to use this function. You need right to edit to the vehicle in the "Administration" application.',
    noCostEntries: 'No cost entries this month',
    noDriverRegistered: 'No driver registered.',
    noLogbooks: 'You do not have Logbooks',
    noOpenRides: 'Great! You do not have open rides',
    noReceipt: 'No costs have been added for this month.',
    noAvailableAssets: 'No available assets',
    noReceiversSelected: 'No receivers selected',
    noStopSelected: 'No stop selected',
    notADriver: 'You are not a driver',
    notripsIntheTime: 'You have no processed trips in the selected time period',
    numberOfErrors: 'Number of errors',
    numberOfMarkedTours: 'Number of marked tours',
    noResult: 'No result.',
    notifications: 'Notifications',
    of: 'of',
    onBoarding: 'Start introduction',
    onlyMe: 'Only me',
    OnlySevenDayWarning: 'Only with 7-day warning',
    OnlyToursFromToday: 'Only tours from today',
    open: 'Open',
    openTours: 'Open tours',
    openToursFound: 'Archive not possible. The following logbooks still have open tours: ',
    otherDriverRegistered: 'Other driver registered',
    otherIncome: 'Other income',
    outOfRange: 'The entered address is outside the 5 km search radius.',
    otherUser: 'Other User',
    overview: 'Overview',
    p401: 'Unauthorized',
    p404: 'Page not found',
    pendingLogbookMessage: 'Incomplete! Please calibrate mileage.',
    pleaseEnterEmail: 'Please enter email',
    pleaseEnterValidEmail: 'Please enter valid email addresses',
    pleaseSelectDataFromat: 'Please select data format',
    pointerFehler: 'Only commas are acceptable as decimal pointer',
    previewTourSplitting: 'Preview tour splitting',
    privateSwitch: 'Private switch',
    privateTour: 'Private trip',
    provider: 'Provider',
    addressEdit: 'Correct addresses',
    reasonOfTheTour: 'Reason of the Tour',
    quantity: 'quantity',
    quantityFehler: 'Quantity must be greater than zero',
    readingDate: 'Reading date',
    reason: 'Reason',
    reasonAlreadyDeleted: 'Reason already deleted',
    reasonAlreadyExists: 'Reason already exists',
    reasons: 'Reasons',
    reasonsImport: 'Import reasons',
    reasonsImported: 'Reasons imported',
    reasonsCorrection: 'Reason for correction',
    reasonsUpdated: 'Reasons updated',
    reasonError: 'Changes must be justified.',
    receiptCreationError: 'Entry could not be created',
    receiptDeleted: 'Cost entry was deleted',
    receiptDeletedFehler: 'Cost entry was not deleted successfully',
    receiptNumber: 'Receipt number',
    receiptNumberFehler: 'Receipt number must be entered',
    receipts: 'Receipts',
    receiptUpdated: 'Cost entry was updated',
    recipient: 'Recipient',
    remove: 'Remove',
    removeAccess: 'Remove access',
    registerAsDriver: 'Login as a driver',
    repair: 'Repair',
    rowsPerPage: 'Rows per page',
    saturday: 'Saturday',
    save: 'Save',
    search: 'Search',
    searchAndSelectStops: 'Search and select stops',
    searchedAddress: 'No address found',
    searchInList: 'Search in the list',
    searchLogbook: 'Search logbook ...',
    selectLogbook: 'Select logbook',
    selectDFormat: 'Select data format',
    selectDriver: 'Select driver',
    selected: 'selected',
    select: 'SELECT',
    selectedContent: 'Select content',
    selectVehicles: 'Select vehicles',
    selectFile: 'SELECT A FILE',
    selectFileToUpload: 'Select the file (xlsx, xls, csv) you want to upload.',
    selectFileToUploadError: 'Column "Zweck" was not found in file.',
    selectRecipients: 'Select recipients',
    selectUsers: 'Select users',
    selectUserAndRole: 'Select user and role',
    settings: 'Settings',
    sevenDaysWarning: 'Shortly, some trips will be set to private by the 7 day rule - you should process these immediately.',
    send: 'Send',
    sharedWith: 'Shared with',
    showMore: 'Show more',
    singleTours: 'Single tours',
    skip: 'Skip',
    splittedTourSuccessfully: 'Tour successfully splitted',
    splitTour: 'Split tour',
    start: 'Start',
    startTime: 'Start time',
    startAddress: 'Start address',
    endAddress: 'Destination address',
    status: 'Status',
    statusInvalid: 'not valid',
    statusValid: 'current',
    Strom: 'Electricity',
    successfulExport: 'The export was successful',
    suggestions: 'Suggestions',
    sunday: 'Sunday',
    support: 'Support',
    takeAPic: 'TAKE A PHOTO',
    templatesForReason: 'Templates for reason of driving',
    thisMonth: 'This Month',
    tuesday: 'Tuesday',
    thursday: 'Thursday',
    till: 'Till',
    timeMoreThan10: 'The time must not deviate by more than 10 minutes.',
    to: 'To',
    toManyLogbooks: 'Select only one logbook!!',
    tooltipSetDriver: 'will be set as the driver',
    totalfee: 'Total costs, gross',
    totalFehler: 'Total must be entered',
    tourEditedSuccessfully: 'Tour edited successfully',
    tourMarkedAsCommute: 'Tour marked as commute',
    tourMarkedAsPrivate: 'Tour marked as private',
    toursEditedSuccessfully: 'Tours edited successfully',
    twoHouseholds: 'Two households',
    typeFehler: 'Type must be entered',
    typeOfTour: 'Type of Tour',
    uneditedTours: 'unedited tours',
    unitPrice: 'Unit price',
    unitPriceFehler: 'The unit price must be greater than zero.',
    unknown: 'Unknown',
    unknownLicensePlate: 'License plate unknown',
    unsuccessfulExport: 'The export has failed',
    update: 'Update',
    updateEntry: 'Update Entry',
    updateError: 'Update failed',
    updateSuccessful: 'Update successful',
    uploadData: 'Upload data',
    user: 'User',
    userDoseNotExist: 'User does not exist or does not have a license',
    userWithLogbookAccess: 'Users with access to this logbook',
    validImage: 'Please add a valid image type',
    various: 'Various',
    variousRoles: 'Various roles',
    variousTopics: 'Various topics',
    vat: 'added tax',
    vatFehler: 'Tax must be greater than zero',
    vatShort: 'Vat',
    vehicle: 'Vehicle',
    vehicles: 'Vehicles',
    vehicleDriver: 'Who drives this vehicle?',
    vehicleStatus: 'Vehicle status',
    vehicleStatusContent: 'Login to a vehicle to activate the private switch or let them navigate to the location.',
    verndorFehler: 'Provider must be entered',
    view: 'VIEW',
    viewer: 'Viewer',
    viewOrAdjustTime: 'You can view your open trips or adjust the time period.',
    viewOrExportTours: 'You can view your edited trips or make an export.',
    visitedContact: 'Visited contact',
    Wasserstoff: 'Hydrogen',
    wednesday: 'Wednesday',
    welcomToFB: 'Welcome to the Logbook',
    whoDrove: 'Who drove?',
    warningVehical: 'Vehicle must be stationary! If the mileage was transferred via the GPS box, please check the data.',
    ziel: 'Destination',
    archive: 'Archive',
    archiveStatusUpdate: 'Logbook was successfully archived',
    logbooksUpdated: 'Logbooks has been successfully updated',
    privateNotify: 'Private trip warning',
    users: 'Users',
    noAccess: 'No access',
    generalAccess: 'General access',
    individualAccess: 'Individual access',
    allLogbooks: 'All Logbooks',
    activeLogbooks: 'Active Logbooks',
    pendingLogbooks: 'Pending logbooks',
    archiveLogbooks: 'Archived Logbooks',
    adminEditNotificationWarning: 'Some users may not have access to the selected logbooks.',
    noLogbooksWithTheFilter: 'No logbooks with this Filter exist.',
    accessChangedError: 'Error when changing accesses',
    accesssChangedSuccessfully: 'Accesses successfully changed',
    gpsErorr: 'GPS inaccuracy/recording error',
    other: 'Other',
    pleaseSelect: 'Please select',
    addressChangeReason: 'Reason',
    tourCorrection: 'Travel correction due to',
    costCentres: 'Cost Centres',
    costObjects: 'Cost Objects',
    additionalConfirmationCalibration: 'I have checked the mileage for correctness and can also confirm that the vehicle is currently parked.',
};
