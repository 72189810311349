import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import Check from '@mui/icons-material/Check';
import { GetAccessStrings, GetAccessMenu } from '../../components/other/ConfigBasic';
import { ListItemText } from '@mui/material';
import { useOutletContext } from 'react-router-dom';

export default function AccessMenu(props) {
    const { variant, updateLogbookAccess } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const checkIndex = (optionValue) => {
        return optionValue === props.currentAccess;
    };
    const [selectedIndex, setSelectedIndex] = React.useState(GetAccessMenu().findIndex(checkIndex));
    const open = Boolean(anchorEl);
    const userAccess = useOutletContext().userAccess;

    const handleButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event, index) => {
        index <= 2 ? setSelectedIndex(index) : setSelectedIndex(-1);
        setAnchorEl(null);
        handleAction(index);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleAction = (action) => {
        const accessObject = GetAccessStrings();
        const changedAccess = [
            {
                holder: props.holder,
                allowed: [],
                variant: variant,
                dtNotAfter: null,
                dtNotBefore: null,
            },
        ];
        switch (action) {
            case 0:
                changedAccess[0].allowed = accessObject.allowed.view;
                updateLogbookAccess(changedAccess);
                break;
            case 1:
                changedAccess[0].allowed = accessObject.allowed.edit;
                updateLogbookAccess(changedAccess);
                break;
            case 2:
                changedAccess[0].allowed = accessObject.allowed.grant;
                updateLogbookAccess(changedAccess);
                break;
            case 3:
                props.setShowExpiryDate(true);
                break;
            case 4:
                updateLogbookAccess(changedAccess);
                break;
            default:
                break;
        }
    };

    return (
        <div>
            <Button
                variant='outlined'
                color='primary'
                id='lock-button'
                aria-controls={open ? 'lock-menu' : undefined}
                aria-haspopup='listbox'
                aria-expanded={open ? 'true' : undefined}
                disableElevation
                disabled={userAccess > 1}
                onClick={handleButtonClick}
                endIcon={<KeyboardArrowDownIcon />}
            >
                {props.currentAccess}
            </Button>
            <Menu
                id='lock-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox',
                }}
            >
                {GetAccessMenu().map((option, index) => (
                    <MenuItem key={option} onClick={(event) => handleMenuItemClick(event, index)} divider={index === 2}>
                        {index === selectedIndex && (
                            <>
                                <ListItemIcon>
                                    <Check />
                                </ListItemIcon>
                                <ListItemText>{option}</ListItemText>
                            </>
                        )}
                        {index !== selectedIndex && <ListItemText style={{ paddingLeft: '36px' }}>{option}</ListItemText>}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}
