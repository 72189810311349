import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { visuallyHidden } from '@mui/utils';
import SimpleSnackbar from '../ui/SimpleSnackbar';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import ErrorSvg from '../../img/ErrorSvg';
import NoLogbooksSvg from '../../img/NoLogbooksSvg';
import ImportReasonDialog from './ImportReasonDialog';
import { useUpdateLogbookPrivateJourney, useUpdateLogbookArchiveStatus, useGetTeamId, useUpdateArchivedLogbookAccess } from '../../api/LogbookApi';
import DialogCalibrateOdometer from '../../routes/Setting/DialogCalibrateOdometer';
import { useOutletContext } from 'react-router-dom';
import { useEffect } from 'react';
import { useRef } from 'react';
import LaunchSvg from '../../img/LaunchSvg';
import { getBreakPoints } from '../other/ConfigBasic';
import AdminTableToolBarMenu from './AdminTableToolBarMenu';
import EditReasonDialog from './EditReasonDialog';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import AdminTableFilterMenu from './AdminTableFilterMenu';
import AdminFahrerDialog from './AdminFahrerDialog';
import NotificationDialog from './NotificationDialog';
import AdminAccessDialog from './AdminAccessDialog';
import { useApolloClient } from '@apollo/client';

function descendingComparator(a, b, orderBy) {
    if (a[orderBy] === null) {
        return -1;
    }
    if (b[orderBy] === null) {
        return 1;
    }
    if (b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase()) {
        return -1;
    }
    if (b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase()) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const statusA = a[0].status;
        const statusB = b[0].status;
        if (statusA === 'pending' && statusB === 'pending') {
            return a[1] - b[1];
        } else if (statusA === 'pending') {
            return -1;
        } else if (statusB === 'pending') {
            return 1;
        } else {
            return comparator(a[0], b[0]);
        }
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = (translations) => {
    return [
        {
            id: 'name',
            numeric: false,
            disablePadding: false,
            label: translations.name,
        },

        {
            id: 'licensePlate',
            numeric: false,
            disablePadding: false,
            label: translations.licensePlate,
        },
        {
            id: 'vin',
            numeric: false,
            disablePadding: false,
            label: 'VIN',
        },
        {
            id: 'privateSwitch',
            numeric: false,
            disablePadding: false,
            label: translations.privateSwitch,
        },
    ];
};

function AdminTableHead(props) {
    const { t } = useTranslation();
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const headCellsTranslations = () => {
        return {
            name: t('name'),
            licensePlate: t('licensePlate'),
            privateSwitch: t('privateSwitch'),
        };
    };

    return (
        <TableHead>
            <TableRow
                sx={{
                    '.MuiTableCell-root': {
                        fontSize: '14px',
                        fontFamily: 'CircularProBook',
                        color: '#757575',
                    },
                    '.Mui-active': {
                        color: '#757575 !important',
                    },
                }}
            >
                <TableCell padding='checkbox' sx={{ width: '80px' }}>
                    <Checkbox
                        color='primary'
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'Alle Fahrtenbücher wählen',
                        }}
                    />
                </TableCell>
                {headCells(headCellsTranslations()).map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component='span' sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

AdminTableHead.propTypes = {
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    numSelected: PropTypes.number.isRequired,
    rowCount: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
};

const AdminTableToolbar = (props) => {
    const { toggleFilterMenu, selected, setMessage, setSelected, updateLogbookPrivateJourney, updateLogbookArchiveStatus, logbooks, logbookFilter } = props;
    const { t } = useTranslation();
    const [openEditReasonDialog, setOpenEditReasonDialog] = useState(false);
    const [openImportReasonDialog, setOpenImportReasonDialog] = useState(false);
    const [filterIconClicked, setFilterIconClicked] = useState(false);
    const [openDriversDialog, setOpenDriversDialog] = useState(false);

    const [openNotificationDialog, setOpenNotificationDialog] = useState(false);
    const [openAccessDialog, setOpenAccessDialog] = useState(false);
    const { teamID } = useGetTeamId();

    const toggleMenuButton = () => {
        toggleFilterMenu();
        setFilterIconClicked(!filterIconClicked);
    };

    return (
        <Toolbar
            sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                paddingLeft: '12px !important',
            }}
        >
            {selected?.length > 0 ? (
                <>
                    <CloseIcon sx={{ cursor: 'pointer', marginRight: '24px' }} onClick={() => setSelected([])} />
                    <Typography sx={{ display: 'flex', flexWrap: 'nowrap', color: '#212121', fontSize: '16px', fontFamily: 'CircularProMedium' }} component='div'>
                        {selected?.length} {t('selected')}
                    </Typography>
                </>
            ) : (
                <div style={{ display: 'contents' }}>
                    <Typography sx={{ flex: '1 1 100%', color: '#212121', fontSize: '20px', fontFamily: 'CircularProMedium', marginRight: 'auto' }} id='tableTitle' component='div'>
                        {t('logbooks')}
                    </Typography>
                    <IconButton
                        onClick={toggleMenuButton}
                        size='small'
                        sx={{
                            backgroundColor: filterIconClicked ? '#b9f6ca' : '',
                            borderRadius: '50%',
                            height: '30px',
                            width: '30px',
                            '&:hover': {
                                backgroundColor: '#b9f6ca',
                            },
                            '&:active': {
                                backgroundColor: '#b9f6ca',
                            },
                        }}
                        aria-label='filter'
                    ></IconButton>
                </div>
            )}

            {selected.length > 0 && (
                <>
                    <AdminTableToolBarMenu
                        setOpenEditReasonDialog={setOpenEditReasonDialog}
                        setOpenImportReasonDialog={setOpenImportReasonDialog}
                        updateLogbookPrivateJourney={updateLogbookPrivateJourney}
                        updateLogbookArchiveStatus={updateLogbookArchiveStatus}
                        setOpenDriversDialog={setOpenDriversDialog}
                        setOpenNotificationDialog={setOpenNotificationDialog}
                        setOpenAccessDialog={setOpenAccessDialog}
                        selected={selected}
                        setMessage={setMessage}
                        logbooks={logbooks}
                        setSelected={setSelected}
                    />
                    <AdminFahrerDialog
                        selectedLogbooks={selected}
                        setMessage={setMessage}
                        showAvatar={true}
                        setSelected={setSelected}
                        open={openDriversDialog}
                        setOpen={setOpenDriversDialog}
                        logbookFilter={logbookFilter}
                    />
                    <NotificationDialog
                        selectedLogbooks={selected}
                        setMessage={setMessage}
                        setSelected={setSelected}
                        open={openNotificationDialog}
                        setOpen={setOpenNotificationDialog}
                    />
                    {openAccessDialog && (
                        <AdminAccessDialog
                            open={openAccessDialog}
                            setOpen={setOpenAccessDialog}
                            selectedLogbooks={selected}
                            setSelectedLogooks={setSelected}
                            teamId={teamID}
                            setMessage={setMessage}
                            logbookFilter={logbookFilter}
                        />
                    )}
                    <ImportReasonDialog selected={selected} setMessage={setMessage} open={openImportReasonDialog} setOpen={setOpenImportReasonDialog} />
                    <EditReasonDialog selected={selected} setMessage={setMessage} open={openEditReasonDialog} setOpen={setOpenEditReasonDialog} />
                </>
            )}
        </Toolbar>
    );
};

const getUpdatedList = (providedlogbookList, logbookFilter, t) => {
    let list = [];

    logbookFilter.forEach((filter) => {
        if (filter.name === 'User') {
            providedlogbookList = providedlogbookList.filter((logbook) => Array.isArray(logbook.access) && logbook.access.some((access) => access.holder === filter.value));
        }
    });

    providedlogbookList.forEach((logbook) => {
        list.push({
            id: logbook.id,
            name: logbook.name,
            status: logbook.status,
            idAsset: logbook.idAsset,
            idDrivers: logbook.idDrivers,
            access: logbook.access,
            licensePlate: logbook?.asset?.child?.licensePlate ? logbook?.asset?.child?.licensePlate : '-',
            vin: logbook?.asset?.child?.vin ? logbook?.asset?.child?.vin : '-',
            privateSwitch:
                logbook?.asset?.privateJourney === null
                    ? t('deactivated').toLowerCase()
                    : logbook?.asset?.privateJourney[logbook?.asset?.privateJourney.length - 1]?.end === null
                    ? t('activated')
                    : t('deactivated').toLowerCase(),
        });
    });
    return list;
};

export default function AdminTable({ providedlogbookList, logbookFilter, setLogbookFilter }) {
    const logbookList = useRef([]);
    const desktop900 = useMediaQuery(getBreakPoints().desktop900);
    const setLogTour = useOutletContext().setLogTour;
    const { t } = useTranslation();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [message, setMessage] = useState('');
    const [hoveredLogbook, setHoveredLogbook] = useState('');
    const [hoveredColumnName, setHoveredColumnName] = useState('');
    const [openFilterMenu, setOpenFilterMenu] = useState(false);
    const client = useApolloClient();
    const loginUser = useOutletContext().loginUser;
    const [calibrateOdometerData, setCalibrateOdometerData] = useState({
        asset: undefined,
        show: false,
    });
    const { updateLogbookPrivateJourney } = useUpdateLogbookPrivateJourney();
    const { updateLogbookArchiveStatus } = useUpdateLogbookArchiveStatus();
    const { updateArchivedLogbookAccess } = useUpdateArchivedLogbookAccess(setMessage);

    useEffect(() => {
        logbookList.current = getUpdatedList(providedlogbookList, logbookFilter, t);
        setSelected([]);
        setPage(0);
        // eslint-disable-next-line
    }, [logbookFilter, providedlogbookList]);

    if (logbookList.current.length === 0 && logbookFilter.length === 1 && logbookFilter[0].value === 'all') {
        return (
            <>
                <Box sx={{ width: '100%', p: 0, m: 0 }}>
                    <NoLogbooksSvg />
                </Box>
            </>
        );
    }

    const prepareCalibrateOdometerData = (asset) => {
        setCalibrateOdometerData({
            asset: asset,
            show: true,
        });
    };

    const toggleFilterMenu = () => {
        setOpenFilterMenu(!openFilterMenu);
    };

    const handleFilterChange = (filter) => {
        if (filter.value.trim() !== '') {
            const filterIndex = logbookFilter.findIndex((f) => f.name === filter.name);
            if (filterIndex !== -1) {
                setLogbookFilter((prevFilters) => [...prevFilters.slice(0, filterIndex), filter, ...prevFilters.slice(filterIndex + 1)]);
            } else {
                setLogbookFilter((prevFilters) => [...prevFilters, filter]);
            }
        } else {
            const updatedFilters = logbookFilter.filter((f) => f.name !== filter.name);
            setLogbookFilter(updatedFilters);
        }
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = logbookList.current.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleCheckboxClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected?.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
        setHoveredLogbook('');
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - logbookList.current?.length) : 0;

    const checkHoveredLogbook = (id) => {
        if (hoveredLogbook !== id) {
            setHoveredLogbook(id);
        }
    };

    const checkStatus = (status, id) => {
        if (status === 'pending') {
            return (
                <Box
                    onMouseEnter={() => checkHoveredLogbook(id)}
                    onMouseLeave={() => checkHoveredLogbook('')}
                    sx={{ position: 'absolute', top: '50%', left: '50%', translate: '-50% -50%', zIndex: 1, height: '24px', width: '24px' }}
                >
                    {hoveredLogbook !== id && <ErrorSvg fill={'#fff'} size={24} />}
                </Box>
            );
        }
    };

    const checkHoveredColumnName = (id) => {
        if (hoveredColumnName !== id) {
            setHoveredColumnName(id);
        }
    };

    const checkColumnName = (id) => {
        return (
            <Box sx={{ position: 'absolute', top: '50%', right: '-4px', translate: '-50% -50%', zIndex: 1, height: '24px', width: '24px' }}>
                {hoveredColumnName === id && <LaunchSvg size={24} />}
            </Box>
        );
    };

    const removeAllAccess = (logbook) => {
        const emptyAccess = [];
        logbook.access.forEach((a) => {
            emptyAccess.push({
                allowed: [],
                dtNotAfter: a.dtNotAfter,
                dtNotBefore: a.dtNotBefore,
                holder: a.holder,
                variant: a.variant,
            });
        });
        return emptyAccess;
    };

    const updatePrivateJourney = (privateSwitch, callback) => {
        logbookList.current
            .filter((logbook) => selected.includes(logbook.id))
            .filter((logbook) => logbook.status === 'active')
            .filter(
                (logbook) =>
                    (logbook.privateSwitch === t('activated') && privateSwitch !== true) || (logbook.privateSwitch === t('deactivated').toLowerCase() && privateSwitch !== false)
            )
            .forEach((logbook) => {
                updateLogbookPrivateJourney({
                    variables: {
                        idLogbook: logbook.id,
                        privateJourneyOn: privateSwitch,
                    },
                    onCompleted: () => {
                        if (callback) {
                            callback(true);
                        } else {
                            setMessage(privateSwitch ? t('modeActivated') : t('modeDisabled'));
                            setSelected([]);
                        }
                    },
                    onError: (error) => {
                        setMessage(error.graphQLErrors[0].message);
                        if (callback) {
                            callback(false);
                        }
                    },
                    refetchQueries: ['getAllLogbooks'],
                });
            });
    };

    const updateLogbookStatus = () => {
        logbookList.current
            .filter((logbook) => selected.includes(logbook.id))
            .forEach((logbook) => {
                updateLogbookArchiveStatus({
                    variables: {
                        idLogbook: logbook.id,
                        status: 'archived',
                        access: removeAllAccess(logbook),
                    },
                    context: {
                        headers: {
                            'x-customer-super': 'on',
                        },
                    },
                    onCompleted: () => {
                        client.refetchQueries({
                            include: ['getAllLogbooks'],
                        });
                        setMessage(t('archiveStatusUpdate'));
                        setSelected([]);
                    },
                    onError: (error) => {
                        setMessage(error.graphQLErrors[0].message);
                    },
                });
            });
    };

    const checkLogbook = async (logbook) => {
        const userHasAccess = logbook.access.some((a) => {
            return a.holder === loginUser.id;
        });
        if (!userHasAccess) {
            await updateArchivedLogbookAccess({
                variables: { id: logbook.id, access: { allowed: ['view'], dtNotAfter: null, dtNotBefore: null, holder: loginUser.id, variant: 'Person' } },
            });
        }
        setTimeout(() => {
            setLogTour(logbook.id);
        }, 400);
    };

    return (
        <>
            <Box sx={{ p: 0, m: 0 }}>
                <Card variant='outlined' sx={desktop900 ? { p: 0, margin: 'auto', width: 'max-content' } : { p: 0, m: 0 }}>
                    <CardContent>
                        <AdminTableToolbar
                            toggleFilterMenu={toggleFilterMenu}
                            selected={selected}
                            setMessage={setMessage}
                            setSelected={setSelected}
                            updateLogbookPrivateJourney={updatePrivateJourney}
                            updateLogbookArchiveStatus={updateLogbookStatus}
                            logbooks={logbookList.current}
                            logbookFilter={logbookFilter}
                        />
                        <AdminTableFilterMenu logbookFilters={logbookFilter} onFilterChange={handleFilterChange} />

                        <TableContainer>
                            <Table sx={{ width: 'max-content' }} aria-labelledby='tableTitle' size={'medium'}>
                                <AdminTableHead
                                    onSelectAllClick={handleSelectAllClick}
                                    order={order}
                                    orderBy={orderBy}
                                    numSelected={selected?.length}
                                    rowCount={logbookList.current?.length}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                                rows.slice().sort(getComparator(order, orderBy)) */}
                                    {logbookList.current.length > 0 ? (
                                        stableSort(logbookList.current, getComparator(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, index) => {
                                                const isItemSelected = isSelected(row.id);
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return (
                                                    <TableRow
                                                        role='checkbox'
                                                        aria-checked={isItemSelected}
                                                        tabIndex={-1}
                                                        key={row.id}
                                                        selected={isItemSelected}
                                                        sx={{
                                                            position: 'relative',
                                                            '.MuiTableCell-root': {
                                                                fontSize: '14px',
                                                                fontFamily: 'CircularProMedium',
                                                                color: '#212121',
                                                            },
                                                        }}
                                                    >
                                                        <TableCell
                                                            padding='checkbox'
                                                            sx={{
                                                                width: '80px',
                                                            }}
                                                            onClick={(event) => handleCheckboxClick(event, row.id)}
                                                        >
                                                            <Box position={'relative'} width={'40px'}>
                                                                <Checkbox
                                                                    color='primary'
                                                                    checked={isItemSelected}
                                                                    inputProps={{
                                                                        'aria-labelledby': labelId,
                                                                    }}
                                                                />
                                                                {!isItemSelected && checkStatus(row.status, row.id)}
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell
                                                            id={labelId}
                                                            onClick={() => (row.status === 'archived' ? checkLogbook(row) : setLogTour(row.id))}
                                                            onMouseEnter={() => checkHoveredColumnName(row.id)}
                                                            onMouseLeave={() => checkHoveredColumnName('')}
                                                            sx={{
                                                                width: '200px',
                                                                position: 'relative',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                paddingRight: '32px',
                                                                cursor: 'pointer',
                                                                '&:hover': {
                                                                    backgroundColor: '#f5f5f5',
                                                                },
                                                            }}
                                                            scope='row'
                                                        >
                                                            {row.name} {checkColumnName(row.id)}
                                                        </TableCell>
                                                        {row.status === 'pending' ? (
                                                            <TableCell
                                                                colSpan={3}
                                                                onClick={() => prepareCalibrateOdometerData(row.idAsset)}
                                                                sx={{
                                                                    cursor: 'pointer',
                                                                    '&:hover': {
                                                                        backgroundColor: '#f5f5f5',
                                                                    },
                                                                }}
                                                                scope='row'
                                                            >
                                                                {t('pendingLogbookMessage')}
                                                            </TableCell>
                                                        ) : (
                                                            <>
                                                                <TableCell scope='row'>{row.licensePlate}</TableCell>
                                                                <TableCell scope='row'>{row.vin}</TableCell>
                                                                <TableCell scope='row'>{row.privateSwitch}</TableCell>
                                                            </>
                                                        )}
                                                    </TableRow>
                                                );
                                            })
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={5} sx={{ textAlign: 'center' }}>
                                                {t('noLogbooksWithTheFilter')}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                height: 53 * emptyRows,
                                            }}
                                        >
                                            <TableCell colSpan={5} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component='div'
                            count={logbookList.current?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            labelDisplayedRows={(page) => `${page.from} – ${page.to} ${t('of')} ${page.count}`}
                            labelRowsPerPage={t('rowsPerPage')}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </CardContent>
                </Card>
            </Box>
            {calibrateOdometerData.show && (
                <DialogCalibrateOdometer show={calibrateOdometerData.show} setCalibrateOdometerData={setCalibrateOdometerData} asset={calibrateOdometerData.asset} />
            )}
            {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
        </>
    );
}
