import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from './themes/theme';
import './App.css';
import MatomoTagManager from './components/other/MatomoTagManager';
import LinearProgress from '@mui/material/LinearProgress';
import AppBar from './components/layout/Appbar';
import NavigationRail from './components/layout/NavigationRail';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import NavigationDrawer from './components/layout/NavigationDrawer';
import { useGetInitialData, useGetOpenToursLength } from './api/LogbookApi';
import { CssBaseline } from '@mui/material';
import BottomNavigationComponent from './components/layout/BottomNavigation';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { getBreakPoints, getCookie } from './components/other/ConfigBasic';
import LogbookHelper from './components/other/LogbookHelper';
import 'intro.js/introjs.css';
import 'leaflet/dist/leaflet.css';
import AppSettingsHelper from './components/other/AppSettingsHelper';
import { useRef } from 'react';
import { isUserCustomerAdmin, defineUserAccess } from './components/auth/authorization';

function App() {
    const { t } = useTranslation();
    const desktop900 = useMediaQuery(getBreakPoints().desktop900);
    const location = useLocation();
    const [menuOpen, setMenuOpen] = useState(false);
    const [lastLogbookId, setLastLogbookId] = useState(null);
    const [logbookId, setLogbookId] = useState(lastLogbookId !== null ? lastLogbookId : '');
    const [badgeOpenTours, setBadgeOpenTours] = useState(0);
    const [badgePendingLogbooks, setBadgePendingLogbooks] = useState(0);
    const [showHint, setShowHint] = useState();
    const userAccess = useRef(3);
    let numberOfOpenTours = undefined;
    let navigate = useNavigate();
    const token = getCookie(process.env.REACT_APP_COOKIE_NAME);

    if (token.length === 0) {
        let redirectExtension = location.pathname.slice(1).replace('/', '%2F');
        window.location.href = process.env.REACT_APP_LOGIN_REDIRECT_URL + redirectExtension;
    }

    function updateMenuState() {
        setMenuOpen(!menuOpen);
    }

    function setBadge(newBadge) {
        numberOfOpenTours = newBadge;
        if (numberOfOpenTours !== undefined && badgeOpenTours !== numberOfOpenTours) {
            setBadgeOpenTours(numberOfOpenTours);
        }
    }

    function setLogTour(tourLogId) {
        setLogbookId(tourLogId);
        if (tourLogId === t('newLogbook')) {
            navigate('/create');
        } else if (tourLogId === t('overview')) {
            navigate('/admin');
        } else if (tourLogId === t('selectLogbook')) {
            navigate('/unauthorized');
        } else if (
            location.pathname === '/create' ||
            location.pathname.includes('/edit/') ||
            location.pathname.includes('/mixed/') ||
            location.pathname.includes('/admin') ||
            location.pathname.includes('/unauthorized') ||
            location.pathname.includes('/open/')
        ) {
            navigate('/open');
        } else if (location.pathname.includes('/costs')) {
            navigate('/costsOverview');
        }
    }

    let initialData = useGetInitialData();
    numberOfOpenTours = useGetOpenToursLength(logbookId);
    useEffect(() => {
        if (numberOfOpenTours !== undefined && badgeOpenTours !== numberOfOpenTours) {
            setBadgeOpenTours(numberOfOpenTours);
        }
    }, [numberOfOpenTours, badgeOpenTours]);

    useEffect(() => {
        if (
            initialData !== undefined &&
            initialData.validation !== undefined &&
            !initialData.validation[0] &&
            initialData.validation[1] &&
            !location.pathname.includes('/unauthorized')
        ) {
            navigate('/unauthorized');
        } else if (initialData !== undefined && initialData.validation !== undefined && !initialData.validation[0] && !initialData.validation[1]) {
            window.location.href = process.env.REACT_APP_ZLOGIN_URL;
        } else if (
            initialData !== undefined &&
            initialData.validation !== undefined &&
            initialData.logbookList.length === 0 &&
            !isUserCustomerAdmin(initialData.userData.me.adminRoles) &&
            !location.pathname.includes('/error/') &&
            !location.pathname.includes('/unauthorized')
        ) {
            navigate('/error/403');
        } else if (
            initialData !== undefined &&
            initialData.logbookList.length === 0 &&
            isUserCustomerAdmin(initialData.userData.me.adminRoles) &&
            !location.pathname.includes('/logbooksCreate') &&
            !location.pathname.includes('/unauthorized')
        ) {
            navigate('/admin/logbooksCreate');
        } else if (location.pathname.includes('/open/')) {
            const pathParts = location.pathname.split('/');
            const newLogbookId = pathParts[pathParts.indexOf('open') + 1];
            setLogbookId(newLogbookId);
        }
        if (
            initialData !== undefined &&
            initialData.logbookList.length > 0 &&
            !initialData.logbookList.some((item) => item.value === logbookId) &&
            logbookId !== t('newLogbook') &&
            logbookId !== t('overview') &&
            logbookId !== t('selectLogbook') &&
            !location.pathname.includes('/unauthorized')
        ) {
            navigate('/unauthorized');
        }

        //Update selected logbook if you cancel the process "create a new logbook"
        if (logbookId === t('newLogbook') && location.pathname !== '/create' && initialData.logbookList.length > 0) {
            setLogbookId(lastLogbookId !== null ? lastLogbookId : initialData.logbookList[0].value);
        }

        //Update selected logbook if a user change the url manual to /create
        if (logbookId !== t('newLogbook') && location.pathname === '/create' && initialData !== undefined && isUserCustomerAdmin(initialData?.userData?.me?.adminRoles)) {
            setLogbookId(t('newLogbook'));
        }

        //Update selected logbook if a user change the url manual to /admin
        if (logbookId !== t('overview') && location.pathname.includes('/admin') && initialData !== undefined && isUserCustomerAdmin(initialData?.userData?.me?.adminRoles)) {
            setLogbookId(t('overview'));
        }
    }, [initialData, location.pathname, navigate, logbookId, t, lastLogbookId]);

    useEffect(() => {
        if (initialData) {
            const accessArray = initialData?.logbookList?.filter((item) => item?.value === logbookId)[0]?.loginUserAccess;
            const isAdminSite = location.pathname.includes('/admin');
            if (accessArray?.length === 0) {
                navigate('/unauthorized');
            } else {
                defineUserAccess(accessArray, isAdminSite, userAccess);
            }
        }
        // eslint-disable-next-line
    }, [initialData]);

    if (!initialData) {
        return <LinearProgress />;
    }

    //Set initial logbookId for the first call
    if (logbookId === '' && initialData.logbookList.length > 0) {
        setLogbookId(initialData.logbookList[0].value);
    }

    const outletData = () => {
        return {
            loginUser: initialData.userData.me,
            logbookId: logbookId,
            logbookInfo: initialData.logbookList.filter((item) => item.value === logbookId)[0],
            logbookList: initialData.logbookList,
            showHint: showHint,
            errorMsg: initialData.logbookList.length === 0 ? t('errorNoPermissionNoLogbook') : '',
            setBadge: setBadge,
            setBadgePendingLogbooks: setBadgePendingLogbooks,
            setLogTour: setLogTour,
            userAccess: userAccess.current,
        };
    };

    return (
        <ThemeProvider theme={theme}>
            <div className='App' style={{ backgroundColor: theme.palette.success.main }}>
                <MatomoTagManager matomoUrl={process.env.REACT_APP_MATOMO_URL} containerId={process.env.REACT_APP_MATOMO_CONTAINER_ID} />
                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />
                    <AppBar
                        position='fixed'
                        setLogTour={setLogTour}
                        menuOpen={menuOpen}
                        updateMenuState={updateMenuState}
                        logbookId={logbookId}
                        logbookList={initialData.logbookList}
                        loginUser={initialData?.userData?.me}
                        showHint={showHint}
                        lastLogbookId={lastLogbookId}
                        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    />
                    {desktop900 && !menuOpen && <NavigationRail badge={badgeOpenTours} badgePendingLogbooks={badgePendingLogbooks} showHint={showHint} position='fixed' />}
                    <NavigationDrawer
                        menuOpen={menuOpen}
                        updateMenuState={updateMenuState}
                        badge={badgeOpenTours}
                        showHint={showHint}
                        badgePendingLogbooks={badgePendingLogbooks}
                        sx={{ flexGrow: 1, paddingTop: '80px important' }}
                        id={outletData().loginUser.id}
                    />
                    {desktop900 && (
                        <Box
                            sx={{
                                backgroundColor: theme.palette.background.default,
                                position: 'fixed',
                                left: !menuOpen ? '80px' : '250px',
                                top: '64px',
                                padding: '0px',
                                borderTopLeftRadius: '16px',
                                width: '16px',
                                height: '100%',
                            }}
                        ></Box>
                    )}

                    <Box
                        sx={{
                            backgroundColor: theme.palette.background.default,
                            marginLeft: desktop900 && !menuOpen ? '96px' : desktop900 && menuOpen ? '16px' : '0',
                            marginTop: '64px',
                            padding: '16px',
                            overflowX: 'hidden',
                            width: '100%',
                        }}
                    >
                        <Outlet context={outletData()} />
                    </Box>
                    {!desktop900 && <BottomNavigationComponent badge={badgeOpenTours} badgePendingLogbooks={badgePendingLogbooks} showHint={showHint} position='fixed' />}
                </Box>
            </div>
            {logbookId !== '' && logbookId !== t('newLogbook') && logbookId !== t('overview') && outletData().logbookInfo && (
                <LogbookHelper assetId={outletData().logbookInfo.assetId} setShowHint={setShowHint} logbookId={logbookId} />
            )}
            {initialData !== undefined && (
                <AppSettingsHelper setLastLogbookId={setLastLogbookId} lastLogbookId={lastLogbookId} initialData={initialData} logbookId={logbookId} setLogbookId={setLogbookId} />
            )}
        </ThemeProvider>
    );
}

export default App;
