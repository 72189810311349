export const isUserAssetAdmin = (adminRoles) => {
    const ASSETADMIN = 'assetAdmin';
    return adminRoles.includes(ASSETADMIN);
};

export const isUserCustomerAdmin = (adminRoles) => {
    const CUSTOMERADMIN = 'customerAdmin';
    return adminRoles.includes(CUSTOMERADMIN);
};

/**
 * Defines a number that controls the range of functions within the app
 * 1 = user is administrator (grant)
 * 2 = user is editor (edit)
 * 3 = user is viewer (view)
 * @param {Array} accessArray, about the selected logbook access
 * @param {Boolean} isAdminSite, define where the user is
 * @param {Number} userAccess, to set the defined number
 */
export const defineUserAccess = (accessArray, isAdminSite, userAccess) => {
    if (isAdminSite || accessArray?.includes('grant')) {
        userAccess.current = 1;
    } else if (accessArray?.includes('edit')) {
        userAccess.current = 2;
    } else if (accessArray?.includes('view')) {
        userAccess.current = 3;
    }
};
