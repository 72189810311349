import { Card, CardContent, CardMedia, LinearProgress } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { useGetAssetDataApi } from '../../api/LogbookApi';
import VehicleSvg from '../other/VehicleSvg';
import SimpleSnackbar from '../ui/SimpleSnackbar';
import StatusLink from './StatusLink';
import { useOutletContext } from 'react-router-dom';

export default function StatusVehicle(props) {
    const [message, setMessage] = useState('');
    const assetData = useGetAssetDataApi(props.idAsset, setMessage);
    const userAccess = useOutletContext().userAccess;

    if (!assetData) {
        return (
            <>
                <Card variant='outlined' sx={{ maxWidth: '538px', marginBottom: '8px' }}>
                    <CardContent>
                        <LinearProgress />
                    </CardContent>
                </Card>
                {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
            </>
        );
    }

    return (
        <Card
            variant='outlined'
            sx={{
                maxWidth: '538px',
                marginBottom: '8px',
                position: 'relative',
            }}
        >
            {assetData.imageUri && (
                <CardMedia
                    component='img'
                    alt={assetData.make + ' ' + assetData.model}
                    sx={{ height: '100%', maxHeight: '240px' }}
                    src={`${process.env.REACT_APP_AWS_ASSET_PATH}${assetData.imageUri}`}
                />
            )}
            {!assetData.imageUri && <VehicleSvg />}
            {userAccess < 3 && (
                <StatusLink
                    driverRegistration={props.driverRegistration}
                    connectionStatus={props.connectionStatus}
                    setConnectionStatus={props.setConnectionStatus}
                    setMessage={props.setMessage}
                    isPrivatModusActive={props.isPrivatModusActive}
                />
            )}
        </Card>
    );
}
