import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Box, Button, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Toolbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SvgNoCosts from '../../other/SvgNoCosts';
import theme from '../../../themes/theme';
import { visuallyHidden } from '@mui/utils';
import { useState } from 'react';
import InvoiceReceipt from '../../other/InvoiceReceipt';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Moment from 'moment';
import { alpha } from '@mui/material/styles';

function descendingComparator(a, b, orderBy) {
    if (a[orderBy] === null) {
        return -1;
    }
    if (b[orderBy] === null) {
        return 1;
    }
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = (translations) => {
    return [
        {
            id: 'dtBilling',
            numeric: false,
            disablePadding: false,
            label: translations.dtBilling,
        },
        {
            id: 'category',
            numeric: false,
            disablePadding: false,
            label: translations.category,
        },
        {
            id: 'vendor',
            numeric: false,
            disablePadding: false,
            label: translations.vendor,
        },
        {
            id: 'total',
            numeric: true,
            disablePadding: false,
            label: translations.total,
        },
    ];
};

function OpenToursTableHead(props) {
    const { t } = useTranslation();
    const { onSelectAllClick, numSelected, rowCount, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const headCellsTranslations = () => {
        return {
            dtBilling: t('date'),
            category: t('costType'),
            vendor: t('provider'),
            total: t('fee'),
            distance: t('distance'),
        };
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding='checkbox' sx={{ width: '80px' }}>
                    <Checkbox
                        color='primary'
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'Alle Fahrten wählen',
                        }}
                    />
                </TableCell>
                {headCells(headCellsTranslations()).map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component='span' sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const OpenToursTableToolbar = (props) => {
    const { numSelected, rowCount } = props;
    const { t } = useTranslation();

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected === 0 ? (
                <Typography sx={{ flex: '1 1 100%' }} color='inherit' variant='subtitle1' component='div'>
                    {t('costEntries')} {rowCount}
                </Typography>
            ) : (
                <Typography sx={{ flex: '1 1 100%' }} variant='h6' id='tableTitle' component='div'>
                    {numSelected} {t('selected')}
                </Typography>
            )}
        </Toolbar>
    );
};

function CostTable({ rows = [], setOpenDelete, setReceiptId }) {
    const { t } = useTranslation();
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('dtBilling');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [hoveredRow, setHoveredRow] = useState('');
    const navigate = useNavigate();
    const [hoveredInvoiceReceipt, setHoveredInvoiceReceipt] = useState('');
    const userAccess = useOutletContext().userAccess;

    useEffect(() => {
        setPage(0);
    }, [rows]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleCheckboxClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const handleRowClick = (event, id) => {
        navigate('/costs/' + id);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const checkHoveredInvoiceReceipt = (id) => {
        if (hoveredInvoiceReceipt !== id) {
            setHoveredInvoiceReceipt(id);
        }
    };

    const checkImageUrl = (imageUrl, id) => {
        if (imageUrl === null) {
            return (
                <Box
                    onMouseEnter={() => checkHoveredInvoiceReceipt(id)}
                    onMouseLeave={() => checkHoveredInvoiceReceipt('')}
                    sx={{ position: 'absolute', top: '50%', left: '50%', translate: '-50% -50%', zIndex: 1, height: '24px', width: '24px' }}
                >
                    {hoveredInvoiceReceipt !== id && <InvoiceReceipt size={24} fill={'#ff3d00'} />}
                </Box>
            );
        }
    };

    return (
        <>
            {rows.length > 0 ? (
                <>
                    <OpenToursTableToolbar numSelected={selected.length} rowCount={rows.length} />
                    <TableContainer>
                        <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size={'medium'}>
                            <OpenToursTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                onSelectAllClick={handleSelectAllClick}
                                numSelected={selected.length}
                                rowCount={rows.length}
                            />
                            <TableBody onMouseLeave={() => setHoveredRow('')}>
                                {stableSort(rows, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(row.id);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                role='checkbox'
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.id}
                                                selected={isItemSelected}
                                                onMouseEnter={() => setHoveredRow(row.id)}
                                                sx={{
                                                    position: 'relative',
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <TableCell
                                                    padding='checkbox'
                                                    sx={{
                                                        width: '80px',
                                                    }}
                                                    onClick={(event) => handleCheckboxClick(event, row.id)}
                                                >
                                                    <Box position={'relative'} width={'40px'}>
                                                        <Checkbox
                                                            color='primary'
                                                            checked={isItemSelected}
                                                            inputProps={{
                                                                'aria-labelledby': labelId,
                                                            }}
                                                        />
                                                        {!isItemSelected && checkImageUrl(row.imageUrl, row.id)}
                                                    </Box>
                                                </TableCell>
                                                <TableCell onClick={(event) => handleRowClick(event, row.id)} component='th' id={labelId} scope='row' padding='none'>
                                                    {Moment(row.dtBilling).format('DD.MM.YYYY HH:mm')}
                                                </TableCell>
                                                <TableCell onClick={(event) => handleRowClick(event, row.id)} align='left'>
                                                    {row.articles[0] ? t(`${row.articles[0].category.toLowerCase()}`) : ''}
                                                </TableCell>
                                                <TableCell onClick={(event) => handleRowClick(event, row.id)} align='left'>
                                                    {row.vendor}
                                                </TableCell>
                                                {hoveredRow === row.id && userAccess < 3 ? (
                                                    <TableCell align='right' sx={{ position: 'relative' }}>
                                                        <Box
                                                            sx={{
                                                                position: 'absolute',
                                                                paddingRight: '16px',
                                                                right: '0',
                                                                top: '50%',
                                                                transform: 'translateY(-50%)',
                                                                backgroundColor: '#f5f5f5',
                                                            }}
                                                        >
                                                            <Button
                                                                variant='outlined'
                                                                color='error'
                                                                sx={{ marginLeft: '8px' }}
                                                                onClick={() => {
                                                                    setReceiptId(row.id);
                                                                    setOpenDelete(true);
                                                                }}
                                                            >
                                                                {t('delete')}
                                                            </Button>
                                                        </Box>
                                                    </TableCell>
                                                ) : (
                                                    <TableCell onClick={(event) => handleRowClick(event, row.id)} align='right' sx={{ position: 'relative' }}>
                                                        {row.articles[0] ? row.articles[0].total.toFixed(2).replace('.', ',') + ' €' : '0,00 €'}
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: 53 * emptyRows,
                                        }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        labelDisplayedRows={(page) => `${page.from} – ${page.to} ${t('of')} ${page.count}`}
                        labelRowsPerPage={t('rowsPerPage')}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            ) : (
                <Box
                    sx={{
                        width: '100%',
                        textAlign: 'center',
                        padding: '80px 120px',
                    }}
                >
                    <SvgNoCosts />
                    <Typography
                        sx={{
                            color: theme.palette.grey[600],
                            paddingTop: '16px',
                        }}
                    >
                        {t('noReceipt')}
                    </Typography>
                </Box>
            )}
        </>
    );
}

export default CostTable;
