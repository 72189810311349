import * as React from 'react';
import { useGetAppSettings } from '../../api/LogbookApi';
import SimpleSnackbar from '../ui/SimpleSnackbar';
import { useState } from 'react';
import { useEffect } from 'react';

export default function AppSettingsHelper(props) {
    const { setLastLogbookId, lastLogbookId, initialData, logbookId, setLogbookId } = props;
    const [message, setMessage] = useState('');

    const appSettingData = useGetAppSettings(initialData?.userData?.me?.id, setMessage, true);
    const appSettingLastLogbook = appSettingData?.appsettings
        ?.flatMap((settingGroup) => settingGroup?.setting)
        ?.find((setting) => setting?.settingId === 'lastLogbook')?.settingValue;

    useEffect(() => {
        if (appSettingLastLogbook !== undefined && appSettingLastLogbook !== lastLogbookId) {
            setLastLogbookId(appSettingLastLogbook);
            if (appSettingLastLogbook !== logbookId) {
                setLogbookId(appSettingLastLogbook);
            }
        }
        // eslint-disable-next-line
    }, [appSettingLastLogbook, lastLogbookId]);

    return <>{message && <SimpleSnackbar message={message} setMessage={setMessage} />}</>;
}
