import { useGetAssetDataForStatus, useUpdateDriverRegistration } from '../../api/LogbookApi';
import { useEffect } from 'react';
import { checkDriverRegistration } from '../other/ConfigBasic';
import { useTranslation } from 'react-i18next';

export default function AdminCheckAndLogoutDriver(props) {
    const { updateLogbookPrivateJourney, idLogbook, idAsset, isPrivatModusActive, setMessage, setSelected } = props;
    const vehicleData = useGetAssetDataForStatus(idAsset, setMessage);
    const { updateDriverRegistration } = useUpdateDriverRegistration();
    const { t } = useTranslation();
    let registeredDriverId = undefined;

    const updateRegistration = (isPrivateSwitchDeaktivated, onlyUserLogout = false) => {
        if (onlyUserLogout) {
            updateDriverRegistration({
                variables: { id: idLogbook, register: false },
                onCompleted: () => {
                    setMessage(t('driverLogoutInfo'));
                },
                onError: (error) => {
                    setMessage(error.graphQLErrors[0].message);
                },
                refetchQueries: ['getAllLogbooks'],
            });
        } else {
            if (isPrivateSwitchDeaktivated) {
                updateDriverRegistration({
                    variables: { id: idLogbook, register: false },
                    onCompleted: () => {
                        setMessage(t('driverLogoutInfoExtension'));
                    },
                    onError: (error) => {
                        setMessage(error.graphQLErrors[0].message);
                    },
                    refetchQueries: ['getAllLogbooks'],
                });
            }
        }
    };

    const checkAndLogoutUser = async () => {
        registeredDriverId = await checkDriverRegistration(vehicleData.driverRegistration);
        if (registeredDriverId.length > 0) {
            if (isPrivatModusActive) {
                updateLogbookPrivateJourney(false, updateRegistration);
            } else {
                updateRegistration(true, true);
            }
        } else {
            setMessage(t('noDriverRegistered'));
            setSelected([]);
        }
    };

    useEffect(() => {
        if (vehicleData?.driverRegistration) {
            checkAndLogoutUser();
        }
        // eslint-disable-next-line
    }, [vehicleData?.driverRegistration]);
}
