import * as React from 'react';
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import {
    Avatar,
    Box,
    Checkbox,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputBase,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import theme from '../../themes/theme';
import { getFullname, getLetterAvatar } from '../other/ConfigBasic';
import { useEffect } from 'react';

import { useOutletContext } from 'react-router-dom';
import { useContactIsDriverApi, useGetLogbooksDrivers, useSaveLogbookDrivers } from '../../api/LogbookApi';
import CreateDialog from '../createLogbooks/CreateDailog';

const mergeData = (drivers, selectedLogbooksDrivers, t) => {
    const updatedDrivers = [...drivers];

    for (const driverIds of Object.values(selectedLogbooksDrivers)) {
        for (const driverId of driverIds) {
            const existingDriver = updatedDrivers.find((driver) => driver.id === driverId);
            if (!existingDriver) {
                const defaultDriver = {
                    firstname: t('unknown'),
                    lastname: null,
                    id: driverId,
                    isDriver: null,
                    imageUri: null,
                    loginUuid: null,
                };
                updatedDrivers.push(defaultDriver);
            }
        }
    }
    return updatedDrivers;
};

export default function AdminFahrerDialog(props) {
    const { showAvatar, selectedLogbooks, setMessage, setSelected, open, setOpen, logbookFilter } = props;
    const { handleSaveLogbookDrivers, loading, error } = useSaveLogbookDrivers();

    const { t } = useTranslation();

    const [checked, setChecked] = useState([]);
    const selectedLogbooksDrivers = useGetLogbooksDrivers(selectedLogbooks, setMessage, logbookFilter);
    const [selectedDrivers, setSelectedDrivers] = useState({});
    const [initialPersonSet, setInitialPersonSet] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [personName, setPersonName] = useState([]);
    const [drivers, setDrivers] = useState([]);

    let driverData = useContactIsDriverApi(setMessage);

    useEffect(() => {
        if (driverData) {
            setDrivers(driverData);
        }
    }, [driverData]);

    const [driverList, setDriverList] = useState([]);
    const loginUser = useOutletContext().loginUser;
    const [openCreate, setOpenCreate] = useState(false);
    useEffect(() => {
        if (driverList && !initialPersonSet) {
            setPersonName(driverList.filter((driver) => driver.loginUuid === loginUser.loginUuid));
            setInitialPersonSet(true);
        }
    }, [driverList, loginUser.loginUuid, initialPersonSet, setInitialPersonSet]);

    // Set initial driver list
    useEffect(() => {
        if (open) {
            setDriverList(
                mergeData(drivers, selectedLogbooksDrivers, t).filter(
                    (driver) => driver?.firstname?.toLowerCase().includes(searchValue.toLowerCase()) || driver?.lastname?.toLowerCase().includes(searchValue.toLowerCase())
                )
            );
            setSelectedDrivers({ ...selectedLogbooksDrivers });
        }
    }, [open, drivers, selectedLogbooksDrivers, t, searchValue]);

    //Set checked to the values from the selectedLogbooks
    useEffect(() => {
        const keys = Object.values(selectedLogbooksDrivers);
        if (keys.length === 0) {
            setChecked([]);
        } else {
            const flattenedArray = Object.values(selectedLogbooksDrivers).flat();
            const uniqueIds = [...new Set(flattenedArray)];
            const checkedID = uniqueIds.filter((id) => Object.values(selectedLogbooksDrivers).every((logbookIds) => logbookIds.includes(id)));

            setChecked(checkedID);
        }
    }, [selectedLogbooksDrivers, driverList.length]);

    const handleToggle = (driverId) => {
        const currentIndex = checked.indexOf(driverId);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(driverId);
            for (let lg in selectedDrivers) {
                if (!selectedDrivers[lg].includes(driverId)) {
                    selectedDrivers[lg] = [...selectedDrivers[lg], driverId];
                }
            }
        } else {
            newChecked.splice(currentIndex, 1);
            for (let lg in selectedDrivers) {
                selectedDrivers[lg] = selectedDrivers[lg].filter((id) => id !== driverId);
            }
        }
        setChecked(newChecked);
    };

    const handleChangeAll = () => {
        if (checked.length < driverList.length) {
            setChecked(driverList.map((driver) => driver.id));
            for (let lg in selectedDrivers) {
                selectedDrivers[lg] = driverList.map((driver) => driver.id);
            }
        } else {
            setChecked([]);
            for (let lg in selectedDrivers) {
                selectedDrivers[lg] = [];
            }
        }
    };

    const handleSave = async () => {
        setOpenCreate(true);
        const newPersonList = driverList.filter((driver) => checked.includes(driver.id));

        try {
            await handleSaveLogbookDrivers(selectedDrivers, setMessage);
        } catch (error) {
            setMessage(error.message);
        }

        setOpen(false);
        setPersonName(newPersonList);
        setDriverList([]);
        setSelected([]);
    };

    const handleCancel = () => {
        setOpen(false);
        setChecked(
            personName.map((person) => {
                return person.id;
            })
        );
        setDriverList([]);
        setSelected([])
    };

    if (loading) {
        return <CreateDialog open={openCreate} setOpen={setOpenCreate} t={t} totalSteps={Object.keys(selectedDrivers).length} error={error} loading={loading} />;
    }

    return (
        <>
            {/* <MenuItem onClick={handleClickOpen}>
                <ListItemIcon>
                    <DriverSvg />
                </ListItemIcon>
                <ListItemText primary={<Typography sx={style}>{t('drivers')}</Typography>} />
            </MenuItem> */}
            <Dialog open={open} onClose={handleCancel} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
                <DialogTitle id='alert-dialog-title'>{t('selectDriver')}</DialogTitle>
                <DialogContent>
                    <Box
                        display='flex'
                        alignItems='center'
                        justifyContent='space-between'
                        padding='0 8px'
                        border={`solid 1px ${theme.palette.grey[600]}`}
                        borderRadius='4px'
                        marginBottom='8px'
                    >
                        <InputBase placeholder={t('search')} onChange={(e) => setSearchValue(e.target.value)} value={searchValue} />
                        <SearchIcon sx={{ color: theme.palette.grey[600] }} />
                    </Box>
                    <ListItem key='all' disablePadding>
                        <ListItemText id='all' primary={t('all')} sx={{ paddingLeft: '24px' }} />
                        <ListItemIcon sx={{ marginRight: '-7px' }}>
                            <Checkbox
                                edge='start'
                                checked={checked.length === driverList?.length}
                                indeterminate={checked.length > 0 && checked.length < driverList?.length}
                                onClick={() => handleChangeAll()}
                            />
                        </ListItemIcon>
                    </ListItem>
                    <List dense sx={{ height: '300px', overflow: 'scroll', overflowX: 'hidden', minWidth: '400px' }}>
                        {driverList?.length > 0 ? (
                            driverList?.map((data) => {
                                const labelId = `checkbox-list-secondary-label-${data.id}`;
                                return (
                                    <ListItem key={data.id} disablePadding>
                                        <ListItemButton onClick={() => handleToggle(data.id)} sx={{ display: 'flex', justifyContent: 'space-around' }}>
                                            {showAvatar && (
                                                <ListItemAvatar>
                                                    <Avatar
                                                        alt={getFullname(data)}
                                                        src={data.imageUri ? `${process.env.REACT_APP_AWS_CONTACT_PATH}${data.imageUri}` : ''}
                                                        sx={{ color: '#212121', fontSize: '14px', backgroundColor: '#E0E0E0' }}
                                                    >
                                                        {getLetterAvatar(data)}
                                                    </Avatar>
                                                </ListItemAvatar>
                                            )}
                                            <ListItemText id={labelId} primary={getFullname(data)} />
                                            <ListItemIcon sx={{ marginRight: '-16px' }}>
                                                <Checkbox
                                                    edge='end'
                                                    checked={checked.includes(data.id)}
                                                    indeterminate={
                                                        selectedLogbooks.some((logbookId) => selectedDrivers[logbookId]?.includes(data.id)) &&
                                                        !selectedLogbooks.every((logbookId) => selectedDrivers[logbookId]?.includes(data.id)) &&
                                                        !checked.includes(data.id)
                                                    }
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </ListItemIcon>
                                        </ListItemButton>
                                    </ListItem>
                                );
                            })
                        ) : driverList?.length === 0 && searchValue.length === 0 ? (
                            <Stack justifyContent={'center'} alignItems={'center'} height={'100%'}>
                                <CircularProgress />
                            </Stack>
                        ) : (
                            <ListItem>
                                <ListItemText>
                                    <Typography variant='caption' color='gray'>
                                        {t('noResult')}
                                    </Typography>

                                    <Typography variant='h4' color='#00360F' sx={{ mt: '40px' }}>
                                        <a
                                            target='_blank'
                                            rel='noreferrer'
                                            variant='subtitle1'
                                            style={{
                                                color: '#00360F',
                                                textDecoration: 'none',
                                            }}
                                            href={process.env.REACT_APP_ADMIN_CONTACTS}
                                        >
                                            {t('addADriver')}
                                        </a>
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                        )}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button variant='text' color='secondary' onClick={() => handleCancel()}>
                        {t('cancel')}
                    </Button>

                    <Button variant='contained' color='success' onClick={() => handleSave()} disabled={checked.length === 0}>
                        {t('save')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
