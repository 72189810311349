import { Container, Fab } from '@mui/material';
import CostView from '../../components/Cost/CostView';
import CostViewDesktop from '../../components/Cost/CostViewDesktop';
import { useMediaQuery } from '@mui/material';
import { getBreakPoints } from '../../components/other/ConfigBasic';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { useState } from 'react';
import SimpleSnackbar from '../../components/ui/SimpleSnackbar';
import { useEffect } from 'react';
import { useApolloClient } from '@apollo/client';

export default function CostsOverview() {
    const desktop900 = useMediaQuery(getBreakPoints().desktop900);
    const userAccess = useOutletContext().userAccess;
    const [disableButton, setDisapleButton] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [message, setMessage] = useState('');
    const client = useApolloClient();

    useEffect(() => {
        if (location.state !== null && location.state.snackbarMessage !== null) {
            setMessage(location.state.snackbarMessage);
            navigate(location.pathname);
            client.refetchQueries({
                include: ['receipts'],
            });
        }
    }, [navigate, location, client]);

    const handleEditCreateReceipt = () => {
        if (userAccess < 3) {
            navigate('/costs');
        } else {
            setDisapleButton(true);
        }
    };

    // Custom css for the Add button
    const addInvoiceButtonStyle = {
        position: 'fixed',
        bottom: { xs: 80, sm: 80, md: 30, lg: 30, xl: 30 },
        right: { xs: '16px', sm: '16px', md: 30, lg: 30, xl: 30 },
        backgroundColor: '#b9f6ca',
        width: desktop900 ? '182px' : '56px',
        height: desktop900 ? '48px' : '56px',
        borderRadius: desktop900 ? '24px' : '50%',
        boxShadow: desktop900 ? ' 0px 12px 17px #00000024' : '',
    };
    return (
        <>
            <Container sx={{ m: 0, p: 0, mx: `${desktop900 ? 'auto' : '0'}`, height: 'calc(100vh - 96px)' } && desktop900 ? { overflow: 'auto' } : { overflow: 'hidden' }}>
                {desktop900 ? <CostViewDesktop /> : <CostView />}
                {userAccess < 3 && (
                    <Fab color='lightgreen' sx={addInvoiceButtonStyle} onClick={handleEditCreateReceipt} disabled={disableButton}>
                        <AddIcon /> {desktop900 ? t('newEntry') : ''}
                    </Fab>
                )}
            </Container>
            {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
        </>
    );
}
