export const languages_de = {
    access: 'Zugriffe',
    accessInfo: 'Nur im Login angelegte Benutzer mit ausgewählter Logbook Lizenz, können Zugriff erhalten',
    accessChangedSuccessfully: 'Zugriff erfolgreich geändert',
    accessEnd: 'Zugriffsende',
    accessStart: 'Zugriffsbeginn',
    activate: 'Aktivieren',
    activated: 'aktiviert',
    active: 'Aktiv',
    add: 'Hinzufügen',
    addAnotherCostType: 'Weitere Kostenart hinzufügen',
    addAPicture: 'Fügen Sie ein Bild hinzu.',
    addCostCentre: 'Kostenstelle hinzufügen',
    addCostObject: 'Kostenträger hinzufügen',
    addExpiryDate: 'Ablaufdatum hinzufügen',
    addressFehler: 'Adresse muss eingegeben werden',
    addressPlaceholder: 'Straße Hausenummer, PLZ Stadt',
    addressSearch: 'Adresse',
    addUser: 'Nutzer hinzufügen',
    addAssets: 'NEUES ASSET ERSTELLEN',
    admin: 'Administrator',
    adminIsRequired: 'Ein Administrator ist erforderlich',
    administration: 'Übersicht',
    all: 'Alle',
    allUsersAdded: 'Alle Nutzer hinzugefügt',
    annotation: 'Besonderheit',
    annualOvervirw: 'Jährlicher Überblick',
    apiErrorDriverData: 'Fehler beim Abrufen der Fahrerdaten:',
    apply: 'Anwenden',
    arrivalTime: 'Ankunftszeit',
    mileageAtEnd: 'Kilometerstand bei Ankunft',
    atThisPosition: 'An dieser Position',
    Autogas: 'Autogas (LPG)',
    back: 'Zurück',
    bannerExportetTour: 'Diese Fahrt wurde bereits exportiert und kann nicht erneut geändert werden.',
    bannerPrivateTour: 'Eine als Privatfahrt kategorisierte Tour kann nicht mehr bearbeitet werden.',
    bannerPrivateTypeEdit: 'Anpassen der Art aufgrund der 7-Tage-Regelung nicht mehr möglich.',
    baseData: 'Stammdaten',
    Benzin: 'Benzin',
    business: 'Betriebsfahrt',
    byNone: '-',
    bySystem: 'durch GPS Box',
    byUser: 'durch Nutzer',
    calibrateOdometer: 'Kilometerstand kalibrieren',
    calibrationSuccessful: 'Kalibrierung erfolgreich',
    cancel: 'Abbrechen',
    changed: 'Geändert',
    changeHistory: 'Änderungsverlauf',
    changeLogbookDrivers: 'Fahrer erfolgreich geändert',
    changeLogbookName: 'Name erfolgreich geändert',
    checkingLogbooksError: 'Fehler bei der Überprüfen der Fahrtenbücher.',
    chooseVehicle: 'Fahrzeug wählen',
    chooseRole: 'Rolle wählen',
    chooseVehicleForLogbook: 'Wählen Sie ein Fahrzeug für Ihr Fahrtenbuch.',
    chosseAssets: 'Für welche Fahrzeuge möchten Sie Fahrtenbücher anlegen?',
    chosseDrivers: 'Welche Personen sollen in den Fahrtenbüchern als Fahrer auswählbar sein?',
    renameLogbook: 'Fahrtenbuch benennen',
    cleaning_services: 'Reinigungsservice',
    commute: 'Arbeitsweg',
    companies: 'Firmen',
    compareMileage: 'KM-Stand abgleichen',
    confirmArchiv: 'Fahrtenbuch wirklich archivieren? Dieses Fahrtenbuch wird dann keine Fahrten mehr aufzeichnen!',
    confirm: 'Bestätigen',
    confirmDelete: 'Sind Sie sicher, dass Sie die Quittung löschen möchten?',
    confirmPrivateSwitch: 'Bei aktivem Privatschalter werden alle Touren als eine Privatfahrt gespeichert, bis dieser wieder deaktiviert wird.',
    connect: 'Verbinden',
    contacts: 'Kontakte',
    costCentre: 'Kostenstelle',
    costEntries: 'Kosteneinträge',
    costObject: 'Kostenträger',
    costs: 'Kosten',
    completeLBInfo: 'Fahrtenbuch Informationen ergänzen',
    costStepIntro: 'Kosten anlegen und verwalten',
    costType: 'Kostenart',
    create: 'Erstellen',
    createdOn: 'Erstellt am',
    createNewLogbook: 'Neues Fahrtenbuch erstellen',
    createReason: 'Einen Zweck erstellen',
    creator: 'Ersteller',
    currentMileageInKm: 'Aktueller Kilometerstand in km',
    currentMileageVehicle: 'Aktueller Kilometerstand vom Fahrzeug.',
    custNo: 'KdNr.',
    dataManagement: 'Datenverwaltung',
    dataRequestInProgress: 'Datenanfrage in Bearbeitung',
    dataRetrievalFailed: 'Datenabruf fehlgeschlagen',
    date: 'Datum /Uhrzeit',
    dateShort: 'Datum',
    deactivate: 'Deaktivieren',
    deactivated: 'Deaktiviert',
    delete: 'Löschen',
    deleteEntry: 'Beleg löschen',
    Diesel: 'Diesel',
    disconnect: 'Trennen',
    distance: 'Distanz',
    done: 'Fertig',
    dragFileHere: 'Dokument hier reinziehen',
    driver: 'Fahrer',
    drivers: 'Fahrer',
    driverForPayroll: 'Für die Lohnabrechnung muss ein Fahrer angegeben werden.',
    driverLoginInfo: 'Erfolgreich angemeldet.',
    driverLogout: 'Abmelden bestätigen',
    driverLogoutInfo: 'Erfolgreich abgemeldet.',
    driverLogoutInfoExtension: 'Erfolgreich abgemeldet - Privatschalter deaktiviert.',
    driverOverview: 'Fahrer Übersicht',
    enterAddress: 'Adresse eingeben',
    edit: 'Bearbeiten',
    editCostCentre: 'Kostenstelle bearbeiten',
    editCostObject: 'Kostenträger bearbeiten',
    edited: 'Bearbeitet',
    editedOn: 'Bearbeitet am',
    editedTours: 'Bearbeitete Fahrten',
    editExportContant: 'INHALT BEARBEITEN',
    editor: 'Bearbeiter',
    editReasons: 'Zwecke bearbeiten',
    addADriver: 'NEUEN KONTAKT ANLEGEN',
    emailNotifications: 'E-Mail-Benachrichtigungen',
    emailNotificationsInfo: 'Diese Nutzer erhalten E-Mail-Benachrichtigungen.',
    end: 'Ende',
    enterCurrentMileage: 'Aktueller Kilometerstand eintragen',
    Erdgas: 'Erdgas',
    errorDuringImport: 'Fehler beim Import',
    errorNoPermissionNoLogbook: 'Sie haben nicht die nötigen Rechte, um ein Fahrtenbuch zu erstellen und Ihnen ist kein Fahrtenbuch zugewiesen.',
    errorOnATour: 'Fehler bei einer Fahrt.',
    errorOnTours: 'Fehler bei mehreren Fahrten.',
    errorPrivatTypeChange: 'Nur noch Privatfahrt möglich',
    errorsUpdateMultipleLogbooks: 'Fehler beim Aktualisieren von {{number}} Fahrtenbüchern!',
    errorUpdateMultipleLogbooks: 'Fehler beim Aktualisieren von {{number}} Fahrtenbuch!',
    export: 'Exportieren',
    exported: 'exportiert',
    exportErrorMessage: 'Es gibt eine oder mehrere unbearbeitete Touren in diesem Zeitbereich. Bitte bearbeiten Sie diese Touren vor dem Export.',
    exportNotPossible: 'Export nicht möglich',
    fee: 'Beitrag',
    feedback: 'Feedback',
    fehlerImage: 'Das Bild wurde nicht erfolgreich hochgeladen',
    friday: 'Freitag',
    from: 'Von',
    fuel: 'Kraftstoff',
    FUEL_ADBLUE: 'AdBlue',
    FUEL_CNG: 'Erdgas',
    FUEL_DIESEL: 'Diesel',
    FUEL_ELECTRICITY: 'Strom',
    FUEL_GAS: 'Benzin',
    FUEL_H2: 'Wasserstoff',
    FUEL_LPG: 'Autogas (LPG)',
    fuelLevel: 'Kraftstoffstand',
    furtherInformation: 'Weitere Informationen',
    general: 'Allgemein',
    geolocationInfosNotAvailable: 'Standortinformationen sind nicht verfügbar.',
    geolocationNotSupported: 'Die Geolokalisierung wird von diesem Browser nicht unterstützt.',
    geolocationPermissionDenied: 'Der Benutzer hat die Anfrage zur Geolokalisierung abgelehnt.',
    geolocationTimeOut: 'Zeitüberschreitung bei Anforderung zum Abrufen des Benutzerstandorts.',
    geolocationUnknownError: 'Ein unbekannter Fehler ist aufgetreten.',
    gross: 'Brutto',
    grossAmount: 'Bruttobetrag',
    guideText: 'Lassen Sie sich jetzt durch die wichtigsten Funktionen führen',
    imageUpdate: 'Bild wurde nicht aktualisiert',
    import: 'Importieren',
    infoCalibrateOdometer: 'Von der GPS-Box exportierter Kilometerstand.',
    infoPlaceholderMap: 'Hier sehen Sie in Zukunft Ihre Fahrt und können Zwischenstopps direkt auf der Karte auswählen.',
    inspection: 'Inspektion',
    introStepFive: 'Teilen Sie das Fahrtenbuch mit anderen Nutzern, gleichen Sie den Kilometerstand ab, erstellen Sie Zwecke, wählen Sie Fahrer aus und weiteres.',
    introStepFour: 'Erfassen Sie Kosten samt Beleg für verschiedene Kategorien wie Kraftstoff, Reinigungsservice uvm.',
    introStepOne: 'Erstellen Sie ein neues Fahrtenbuch oder wählen Sie ein bestehendes aus',
    introStepOneName: 'Fahrtenbuch wählen oder erstellen',
    introStepThree: 'Sehen Sie bearbeitete Fahrten oder exportieren Sie das Fahrtenbuch',
    introStepTwo:
        'Hier finden Sie alle offenen Fahrten, die noch bearbeitet werden müssen. Mit Wischgesten lassen sich Fahrten direkt als Arbeitsweg oder Privatfahrt kennzeichnen.',
    infoChangeAddress: 'Sie können eine Adresse im Umkreis von maximal 5 km auswählen und bei der Uhrzeit maximal 10 Minuten.',
    introStepTwoMobile:
        'Hier finden Sie alle offenen Fahrten, die noch bearbeitet werden müssen. Mit Wischgesten lassen sich Fahrten direkt als Arbeitsweg oder Privatfahrt kennzeichnen.',
    invalidSelection: 'Ungültige Auswahl',
    kg: 'Kilogramm',
    kgPrice: 'Preis pro Kilogramm',
    kwh: 'Kilowattstunde',
    lastCalibration: 'Letzte Kalibrierung',
    lastPosition: 'Letzter Standort',
    leasing: 'Leasing',
    leaveOverview: 'Übersicht verlassen',
    legalDocuments: 'Rechtliche Dokumente',
    legalNotes: 'Rechtliche Hinweise',
    letsgo: 'Los gehts',
    licensePlate: 'Kennzeichen',
    literprice: 'Preis pro Liter',
    logbook: 'Logbook',
    logbookExport: 'Fahrtenbuch Export',
    logbookListInfo: 'Gesamte Liste, siehe Übersicht',
    logbooks: 'Fahrtenbücher',
    logbooksInfo: 'Unvollständige Fahrtenbücher werden ohne Kilmeterstand angelegt. Werden Fahrtenbücher nicht benannt wird der Fahrzeugname übernommen.',
    logbookSuccessfulCreated: 'Fahrtenbuch erfolgreich erstellt',
    logout: 'Abmelden',
    maillingListUpdated: 'E-Mail-Verteilerliste aktualisiert',
    makeStopover: 'Zwischenstopp machen',
    mapError: 'Tour ist nicht vollständig registriert',
    mandatoryField: 'Pflichtfeld',
    markAsCommute: 'Als Arbeitsweg kennzeichnen',
    markAsPrivatTour: 'Als Privatfahrt kennzeichnen',
    mergeAsPrivateTour: 'Als privat zusammenfügen',
    mergedAsPrivate: 'Wird als Privatfahrt zusammengeführt',
    mergeTours: 'Fahrten zusammenfügen',
    mergeToursAsPrivate: 'Zusammengefügte Fahrten werden automatisch als Privatfahrt gekennzeichnet. Dies kann nicht rückgängig gemacht werden.',
    mileage: 'Kilometerstand',
    mileageAtStart: 'Kilometerstand bei Start',
    mileageBegin: 'Startkilometerstand',
    mileageCalibrated: 'Kilometer abgeglichen',
    mileageEnd: 'Endkilometerstand',
    mileageMustBeCompared: 'Km-Stand muss abgeglichen werden',
    missingPermissionEdit: 'Kein Bearbeitungsrecht',
    missingPermissionsCreateLogbook: 'Du hast nicht genügend Rechte, um ein Fahrtenbuch zu erstellen',
    mixedTour: 'Mischfahrt',
    modeActivated: 'Modus aktiviert',
    modeDisabled: 'Modus deaktiviert',
    monday: 'Montag',
    moreThen5Km: 'Adresse darf nicht mehr als 5 km abweichen.',
    moreThan5KmInputError: 'Abweichung über 5 km',
    monthandYear: 'Monat und Jahr',
    name: 'Name',
    nameOfLogbook: 'Wie soll das Fahrtenbuch heißen?',
    net: 'Netto',
    netFee: 'Gesamtkosten, netto',
    newEntry: 'NEUER EINTRAG',
    newLogbook: 'Neues Fahrtenbuch',
    newReceiptCreated: 'Neuer Kosteneintrag wurde angelegt.',
    newReceipt: 'Neuer Beleg',
    newLogBooks: 'Neue Fahrtenbücher',
    next: 'Weiter',
    noAssetAccess: 'Sie haben nicht die nötigen Rechte, um diese Funktion zu nutzen. Sie benötigen in der Anwendung "Administration" Zugriff auf das Fahrzeug.',
    noAssetEditAccess: 'Sie haben nicht die nötigen Rechte, um diese Funktion zu nutzen. Sie benötigen in der Anwendung "Administration" Bearbeitungsrecht auf das Fahrzeug.',
    noCostEntries: 'Keine Kosteneinträge in diesem Monat',
    noDriverRegistered: 'Kein Fahrer angemeldet.',
    noLogbooks: 'Sie haben keine Fahrtenbücher',
    noOpenRides: 'Super! Sie haben keine offenen Fahrten',
    noAvailableAssets: 'Keine verfügbaren Fahrzeuge',
    noReceipt: 'Für diesen Monat wurden keine Kosten erfasst.',
    noReceiversSelected: 'Keine Empfänger ausgewählt',
    noStopSelected: 'Kein Stopp ausgewählt',
    notADriver: 'Sie sind kein Fahrer',
    notripsIntheTime: 'Sie haben keine bearbeiteten Fahrten im ausgewählen Zeitraum',
    notifications: 'Benachrichtigungen',
    numberOfErrors: 'Anzahl Fehler',
    numberOfMarkedTours: 'Anzahl markierter Fahrten',
    noResult: 'Kein Ergebnis.',
    of: 'von',
    onBoarding: 'Einführung starten',
    onlyMe: 'Nur ich',
    OnlySevenDayWarning: 'Nur mit 7-Tage Warnung',
    OnlyToursFromToday: 'Nur Fahrten von heute',
    open: 'Offen',
    openTours: 'Offene Fahrten',
    openToursFound: 'Archivieren nicht möglich. Folgende Fahrtenbücher haben noch offene Fahrten: ',
    otherDriverRegistered: 'Anderer Fahrer angemeldet',
    otherIncome: 'Andere Einkommensarten',
    outOfRange: 'Die eingegebene Adresse liegt außerhalb des Suchradius von 5 km.',
    otherUser: 'Anderer Nutzer',
    overview: 'Übersicht',
    p401: 'Nicht autorisiert',
    p404: 'Seite nicht gefunden',
    pendingLogbookMessage: 'Unvollständig! Bitte Kilometerstand angeben.',
    pleaseEnterEmail: 'Bitte E-Mail eingeben',
    pleaseEnterValidEmail: 'Bitte geben Sie gültige E-Mail-Adressen ein',
    pleaseSelectDataFromat: 'Bitte Datenformat auswählen',
    pointerFehler: 'Als Dezimalzeiger sind nur Kommas zulässig',
    previewTourSplitting: 'Vorschau Touraufteilung',
    privateSwitch: 'Privatschalter',
    privateTour: 'Privatfahrt',
    addressEdit: 'Adressen korrigieren',
    provider: 'Anbieter',
    reasonOfTheTour: 'Zweck der Fahrt',
    quantity: 'Menge',
    quantityFehler: 'Die Menge muss größer als Null sein',
    readingDate: 'Ablesedatum',
    reason: 'Zweck',
    reasonAlreadyDeleted: 'Zweck bereits gelöscht',
    reasonAlreadyExists: 'Zweck existiert bereits',
    reasons: 'Zwecke',
    reasonsImport: 'Zwecke importieren',
    reasonsImported: 'Zwecke importiert',
    reasonsCorrection: 'Begründung für Korrektur',
    reasonError: 'Änderungen müssen begründet werden.',
    reasonsUpdated: 'Zwecke aktualisiert',
    receiptCreationError: 'Eintrag konnte nicht erstellt werden',
    receiptDeleted: 'Kosteneintrag wurde gelöscht',
    receiptDeletedFehler: 'Kosteneintrag wurde nicht erfolgreich gelöscht',
    receiptNumber: 'Belegnummer',
    receiptNumberFehler: 'Belege Nummer muss eingegeben werden',
    receipts: 'Belege',
    receiptUpdated: 'Kosteneintrag wurde aktualisiert',
    recipient: 'Empfänger',
    registerAsDriver: 'Als Fahrer anmelden',
    remove: 'Entfernen',
    removeAccess: 'Zugriff entfernen',
    repair: 'Reparatur',
    rowsPerPage: 'Zeilen pro Seite',
    saturday: 'Samstag',
    save: 'Speichern',
    search: 'Suchen',
    searchAndSelectStops: 'Suchen und wählen Sie Stopps',
    searchedAddress: 'Keine Adresse gefunden',
    searchLogbook: 'Fahrtenbuch suchen ...',
    selectLogbook: 'Fahrtenbuch auswählen',
    selectDFormat: 'Datenformat auswählen',
    selectDriver: 'Fahrer/in auswählen',
    selectUserAndRole: 'Nutzer und Rolle auswählen',
    selected: 'ausgewählt',
    select: 'AUSWÄHLEN',
    selectedContent: 'Inhalt auswählen',
    selectVehicles: 'Fahrzeuge auswählen',
    selectFile: 'DATEI AUSWÄHLEN',
    selectFileToUpload: 'Wählen Sie die Datei (xlsx, xls, csv) aus die Sie hochladen möchten.',
    selectFileToUploadError: 'Spalte "Zweck" wurde in Datei nicht gefunden.',
    selectRecipients: 'Empfänger auswählen',
    selectUsers: 'Nutzer auswählen',
    searchInList: 'In Liste suchen',
    settings: 'Einstellungen',
    sevenDaysWarning: 'In kürze werden einige Fahrten durch die 7 Tage Regelung auf privat gesetzt - Sie sollten diese umgehend bearbeiten.',
    send: 'Senden',
    sharedWith: 'Geteilt mit',
    showMore: 'Weitere anzeigen',
    singleTours: 'Einzelne Touren',
    skip: 'Überspringen',
    splittedTourSuccessfully: 'Tour erfolgreich aufgeteilt',
    splitTour: 'Fahrt auftrennen',
    start: 'Start',
    startTime: 'Startzeit',
    startAddress: 'Startadresse',
    endAddress: 'Zieladresse',
    status: 'Status',
    statusInvalid: 'nicht gültig',
    statusValid: 'aktuell',
    Strom: 'Strom',
    successfulExport: 'Der Export war erfolgreich',
    suggestions: 'Vorschläge',
    sunday: 'Sonntag',
    support: 'Support',
    takeAPic: 'FOTO MACHEN',
    templatesForReason: 'Vorlagen für Zweck der Fahrt',
    thisMonth: 'Diesen Monat',
    tuesday: 'Dienstag',
    thursday: 'Donnerstag',
    till: 'Bis',
    timeMoreThan10: 'Uhrzeit darf nicht mehr als 10 min abweichen.',
    to: 'Nach',
    toManyLogbooks: 'Nur ein Fahrtenbuch auswählen!',
    tooltipSetDriver: 'wird als Fahrer gesetzt',
    totalfee: 'Gesamtkosten, Brutto',
    totalFehler: 'Gesamtbetrag muss eingegeben werden',
    tourEditedSuccessfully: 'Tour erfolgreich bearbeitet',
    tourMarkedAsCommute: 'Fahrt als Arbeitsweg markiert',
    tourMarkedAsPrivate: 'Fahrt als Privatfahrt markiert',
    toursEditedSuccessfully: 'Touren erfolgreich bearbeitet',
    twoHouseholds: 'Familienheimfahrten',
    typeFehler: 'Typ muss eingegeben werden',
    typeOfTour: 'Art der Fahrt',
    uneditedTours: 'Fahrten unbearbeitet',
    unitPrice: 'Einzelpreis',
    unitPriceFehler: ' Stückpreis muss größer als Null sein',
    unknown: 'Unbekannt',
    unknownLicensePlate: 'Kennzeichen unbekannt',
    unsuccessfulExport: 'Der Export ist fehlgeschlagen',
    update: 'Aktualisieren',
    updateEntry: 'Beleg aktualisieren',
    updateError: 'Aktualisierung fehlgeschlagen',
    updateSuccessful: 'Aktualisierung erfolgreich',
    uploadData: 'Daten hochladen',
    user: 'Nutzer',
    userDoseNotExist: 'Benutzer existiert nicht oder hat keine Lizenz',
    userWithLogbookAccess: 'Nutzer mit Zugriff auf dieses Fahrtenbuch',
    validImage: 'Bitte fügen Sie einen gültigen Bildtyp hinzu',
    various: 'Verschieden',
    variousRoles: 'Verschiedene Rollen',
    variousTopics: 'Verschiedene Themen',
    vat: 'Umsatzsteuer',
    vatFehler: 'Die Umsatzsteuer muss größer als Null sein',
    vatShort: 'USt.',
    vehicle: 'Fahrzeug',
    vehicles: 'Fahrzeuge',
    vehicleDriver: 'Wer fährt dieses Fahrzeug?',
    vehicleStatus: 'Status des Fahrzeugs',
    vehicleStatusContent: 'Melden Sie sich bei einem Fahrzeug an um den Privatschalter zu aktivieren oder lassen Sie sich zum Standort führen',
    verndorFehler: 'Anbieter muss eingegeben werden',
    view: 'ANSEHEN',
    viewer: 'Betrachter',
    viewOrAdjustTime: 'Sie können Ihre offenen Fahrten ansehen oder den Zeitraum anpassen.',
    viewOrExportTours: 'Sie können Ihre bearbeiteten Fahrten ansehen oder einen Export vornehmen.',
    visitedContact: 'Besuchter Kontakt',
    Wasserstoff: 'Wasserstoff',
    wednesday: 'Mittwoch',
    welcomToFB: 'Willkommen im Logbook',
    whoDrove: 'Wer ist gefahren?',
    warningVehical: 'Fahrzeug muss stehen! Wenn Kilometerstand über die GPS-Box übertragen wurde, bitte Daten prüfen.',
    ziel: 'Ziel',
    archive: 'Archivieren',
    archiveStatusUpdate: 'Fahrtenbuch wurde erfolgreich archiviert',
    logbooksUpdated: 'Fahrtenbücher wurden erfolgreich aktualisiert',
    privateNotify: 'Privatfahrt-Warnung',
    users: 'Nutzer',
    noAccess: 'Kein Zugriff',
    generalAccess: 'Allgemeiner Zugriff',
    individualAccess: 'Individueller Zugriff',
    allLogbooks: 'Alle Fahrtenbücher',
    activeLogbooks: 'Aktive Fahrtenbücher',
    pendingLogbooks: 'Unvollständige Fahrtenbücher',
    archiveLogbooks: 'Archivierte Fahrtenbücher',
    adminEditNotificationWarning: 'Einige Nutzer haben eventuell keinen Zugang zu den ausgewählten Fahrtenbüchern.',
    noLogbooksWithTheFilter: 'Keine Fahrtenbücher mit diesem Filter vorhanden.',
    accessChangedError: 'Fehler beim Ändern der Zugriffe',
    accesssChangedSuccessfully: 'Zugriffe erfolgreich geändert',
    gpsErorr: 'GPS Ungenauigkeit/ Aufzeichnungsfehler',
    other: 'Sonstiges',
    pleaseSelect: 'Bitte auswählen',
    addressChangeReason: 'Begründung',
    tourCorrection: 'Fahrtkorrektur wegen',
    costCentres: 'Kostenstellen',
    costObjects: 'Kostenträger',
    additionalConfirmationCalibration: 'Ich habe die Kilometer auf Korrektheit überprüft und kann außerdem bestätigen das sich das Fahrzeug aktuell im Stillstand befindet.',
};
