import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';

/**
 * A simple dialog to confirm an action.
 *
 * @param {Boolean} open shows the current visibility of the dialog.
 * @param {Function} setOpen changed the boolean open.
 * @param {Function} handleConfirm handles the confirmation process.
 * @param {String} dialogContentText is the content to be displayed in the dialog.
 * @param {String} confirmButtonText is the content to be displayed in the confirmButton.
 * @returns a dialog.
 */
export default function ConfirmDialog(props) {
    const { t } = useTranslation();
    const { open, setOpen, handleConfirm, dialogContentText, confirmButtonText, disabled = false } = props;

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={() => handleClose()}
            PaperProps={{
                style: {
                    borderRadius: '28px',
                },
            }}
        >
            <DialogTitle sx={{ padding: '24px 24px 16px 24px' }}>{t('confirm')}</DialogTitle>
            <DialogContent sx={{ padding: '0px 24px 0px 24px' }}>
                <DialogContentText>{dialogContentText}</DialogContentText>
            </DialogContent>
            <DialogActions sx={{ height: '88px', padding: '24px' }}>
                <Button variant='text' color='secondary' onClick={() => handleClose()} sx={{ height: '40px', marginBottom: '0px !important' }}>
                    {t('cancel')}
                </Button>
                <Button
                    disabled={disabled}
                    variant='contained'
                    color='error'
                    sx={{ height: '40px', marginBottom: '0px !important' }}
                    onClick={() => {
                        handleConfirm();
                        handleClose();
                    }}
                >
                    {confirmButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
