import Table from '../../components/Tour/OpenToursTable';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import OnboardingCard from '../../components/Onboarding/OnboardingCard';
import { useGetAppSettings, useUpdateAppSettings } from '../../api/LogbookApi';
import { useOutletContext } from 'react-router';
import { useEffect, useState } from 'react';
import StepsModule from '../../components/Onboarding/Components/Steps';
import SimpleSnackbar from '../../components/ui/SimpleSnackbar';
import { useMediaQuery } from '@mui/material';
import { getBreakPoints } from '../../components/other/ConfigBasic';
import MobileOnBoarding from '../../components/Onboarding/MobileOnBoarding';

export default function Dashboard() {
    const desktop900 = useMediaQuery(getBreakPoints().desktop900);
    const [showOnBoarding, setShowOnBoarding] = useState(true);
    const [enableOnboarding, setStartOnboarding] = useState(false);
    const [boarding, setBoarding] = useState(false);
    const userId = useOutletContext().loginUser.id;
    const [message, setMessage] = useState('');
    const data = useGetAppSettings(userId, setMessage);
    const { updateAppSettings } = useUpdateAppSettings(userId, setMessage);

    useEffect(() => {
        setBoarding(data?.appsettings[0]?.setting[0]?.settingValue);
    }, [data]);

    const handleComplete = () => {
        updateAppSettings({ settingId: 'onboarding', settingValue: 'true' });
        setStartOnboarding(false);
        setBoarding(true);
    };

    return (
        <>
            <StepsModule enableOnboarding={enableOnboarding} stepCount={4} handleComplete={handleComplete} />
            {boarding === 'false' &&
                showOnBoarding &&
                (desktop900 ? (
                    <OnboardingCard state={showOnBoarding} setState={setShowOnBoarding} setShowStep={setStartOnboarding} handleComplete={handleComplete} />
                ) : (
                    <MobileOnBoarding showOnBoarding={showOnBoarding} setShowOnBoardin={setShowOnBoarding} handleComplete={handleComplete} />
                ))}
            <Box className='content' sx={{ flexGrow: 1, mt: '16px' }}>
                <Grid container spacing={2}>
                    <Grid xs={12} md={12} xl={12}>
                        <Table />
                    </Grid>
                </Grid>
            </Box>
            {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
        </>

        //
    );
}
