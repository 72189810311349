import * as React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import { useOutletContext, useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { visuallyHidden } from '@mui/utils';
import NoTours from '../other/NoTours';
import { useGetOpenTours, useGetLogbookDriversApi, useContactIsDriverApi } from '../../api/LogbookApi';
import LinearProgress from '@mui/material/LinearProgress';
import Moment from 'moment';
import MarkTourAsTemplate from './MarkTourAsTemplate';
import { gql, useMutation, useApolloClient } from '@apollo/client';
import SimpleSnackbar from '../ui/SimpleSnackbar';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardContent, useMediaQuery } from '@mui/material';
import MergeTours from './MergeTours';
import OneClickTourUpdate from './OneClickTourUpdate';
import { GetTourTypes, getBreakPoints } from '../other/ConfigBasic';
import OpenToursTableMobile from './OpenToursTableMobile';
import PrivateSvg from '../other/PrivateSvg';
import WarningIcon from '@mui/icons-material/Warning';
import { useState } from 'react';
import OpenToursBanner from './OpenToursBanner';
import OpenToursCheckboxFilter from './OpenToursCheckboxFilter';
import theme from '../../themes/theme';

const UPDATE_TOUR = gql`
    mutation updateTour($id: ID!, $driver: String!, $type: EnumLogbookTourType!) {
        updateLogbooktours(input: { type: $type, driver: $driver }, id: $id) {
            id
        }
    }
`;

const MERGE_LOGBOOKTOURS = gql`
    mutation mergeLogbooktours($idLogbook: ID!, $dtBegin: DateTime!, $dtEnd: DateTime!) {
        mergeLogbooktours(idLogbook: $idLogbook, dtBegin: $dtBegin, dtEnd: $dtEnd) {
            id
        }
    }
`;

function createSnackbarMessage(messageCollection, translations) {
    let errorCounter = 0;
    let errorMessage = '';
    messageCollection.forEach((messageObject) => {
        if (messageObject.type === 'Error') {
            errorCounter++;
            if (errorMessage.length === 0) {
                errorMessage = messageObject.message;
            }
        }
    });

    if (errorCounter === 0 && messageCollection.length === 1) {
        return translations.tourEditedSuccessfully;
    } else if (errorCounter === 0 && messageCollection.length > 1) {
        return translations.toursEditedSuccessfully;
    } else if (errorCounter === 1) {
        return `${translations.errorOnATour} ${errorMessage}`;
    } else if (errorCounter > 1) {
        return `${translations.errorOnTours} ${errorMessage}`;
    } else {
        console.log(`${translations.numberOfErrors}: ${errorCounter} | ${translations.numberOfMarkedTours}: ${messageCollection.length}`);
    }
}

function descendingComparator(a, b, orderBy) {
    if (orderBy === 'streetBegin' && a[orderBy] === null && b[orderBy] === null) {
        orderBy = 'postalBegin';
    }
    if (orderBy === 'streetEnd' && a[orderBy] === null && b[orderBy] === null) {
        orderBy = 'postalEnd';
    }
    if (a[orderBy] === null) {
        return -1;
    }
    if (b[orderBy] === null) {
        return 1;
    }
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = (translations) => {
    return [
        {
            id: 'dtBegin',
            numeric: false,
            disablePadding: false,
            label: translations.dtBegin,
        },
        {
            id: 'streetBegin',
            numeric: false,
            disablePadding: false,
            label: translations.streetBegin,
        },
        {
            id: 'dtEnd',
            numeric: false,
            disablePadding: false,
            label: translations.dtEnd,
        },
        {
            id: 'streetEnd',
            numeric: false,
            disablePadding: false,
            label: translations.streetEnd,
        },
        {
            id: 'distance',
            numeric: true,
            disablePadding: false,
            label: translations.distance,
        },
    ];
};

function OpenToursTableHead(props) {
    const { t } = useTranslation();
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, openTourList, setSelected, daysUntilHint } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const headCellsTranslations = () => {
        return {
            dtBegin: t('start'),
            streetBegin: t('from'),
            dtEnd: t('end'),
            streetEnd: t('to'),
            distance: t('distance'),
        };
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding='checkbox' sx={{ width: '80px' }}>
                    <Checkbox
                        color='primary'
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'Alle Fahrten wählen',
                        }}
                    />
                    <OpenToursCheckboxFilter openTourList={openTourList} setSelected={setSelected} daysUntilHint={daysUntilHint} />
                </TableCell>
                {headCells(headCellsTranslations()).map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component='span' sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

OpenToursTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const OpenToursTableToolbar = (props) => {
    const { selected, numberOfOpenTours, currentLogbook, saveTours, textSelected, getSelectedToursData, mergeTours, userAccess } = props;
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(selected.length > 0 && {
                    bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {selected.length > 0 ? (
                <Typography sx={{ flex: '1 1 100%' }} color='inherit' variant='subtitle1' component='div'>
                    {selected.length} {textSelected}
                </Typography>
            ) : (
                <Typography sx={{ flex: '1 1 100%' }} variant='h6' id='tableTitle' component='div'>
                    {numberOfOpenTours} {t('uneditedTours')}
                </Typography>
            )}

            {selected.length > 0 && userAccess < 3 && (
                <Typography sx={{ flex: '1 1 100%' }} color='inherit' variant='subtitle1' component='div' align='right'>
                    <Button
                        variant='text'
                        color='primary'
                        disabled={selected.length > 1 || (getSelectedToursData()[0] !== null && getSelectedToursData()[0].type === GetTourTypes().PRIVATE)}
                        onClick={() => navigate('/mixed/' + getSelectedToursData()[0].id)}
                    >
                        {t('mixedTour')}
                    </Button>
                    <MergeTours getSelectedToursData={getSelectedToursData} logbookId={currentLogbook} mergeTours={mergeTours} />
                    <MarkTourAsTemplate
                        currentLogbook={currentLogbook}
                        saveTours={saveTours}
                        showIcon={false}
                        successMessage={t('tourMarkedAsPrivate')}
                        icon={<PrivateSvg />}
                        buttonText={t('markAsPrivatTour')}
                        tourType={GetTourTypes().PRIVATE}
                    />
                </Typography>
            )}
        </Toolbar>
    );
};

export default function OpenToursTable() {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('dtBegin');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [message, setMessage] = useState('');
    const [messageCollection, setMessageCollection] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const client = useApolloClient();
    const { t } = useTranslation();
    const [hoveredRow, setHoveredRow] = useState('');
    const desktop900 = useMediaQuery(getBreakPoints().desktop900);
    const tablet = useMediaQuery(theme.breakpoints.between('md', 'xl'));
    const daysUntilHint = 5;
    const setBadge = useOutletContext().setBadge;
    const [showBanner, setShowBanner] = useState(false);
    const userAccess = useOutletContext().userAccess;

    const [updateTour, { reset }] = useMutation(UPDATE_TOUR, {
        refetchQueries: ['getOpenTours'],
    });

    const [mergeLogbooktours] = useMutation(MERGE_LOGBOOKTOURS, {
        refetchQueries: ['getOpenTours'],
    });

    const saveTours = (selectedDriver, tourType, successMessage) => {
        selected.forEach((tourId) => {
            updateTour({
                variables: {
                    id: tourId,
                    driver: selectedDriver,
                    type: tourType,
                },
                onCompleted: () => {
                    messageCollection.push({
                        type: 'Success',
                        message: successMessage,
                    });
                },
                onError: (error) => {
                    messageCollection.push({
                        type: 'Error',
                        message: checkErrorMessage(error.graphQLErrors[0].message),
                    });
                },
            });
        });
    };

    const saveTourOneClick = (tourId, driverFullName, tourType, successMessage) => {
        updateTour({
            variables: {
                id: tourId,
                driver: driverFullName,
                type: tourType,
            },
            onCompleted: () => {
                setMessage(successMessage);
                setSelected([]);
            },
            onError: (error) => {
                setMessage(checkErrorMessage(error.graphQLErrors[0].message));
            },
        });
    };

    const mergeTours = (InputData) => {
        mergeLogbooktours({
            variables: {
                idLogbook: InputData.idLogbook,
                dtBegin: new Date(InputData.dtBegin),
                dtEnd: new Date(InputData.dtEnd),
            },
            onCompleted: (data) => {
                setSelected([]);
                saveTourOneClick(data?.mergeLogbooktours?.id, InputData.driverFullName, GetTourTypes().PRIVATE, t('tourMarkedAsPrivate'));
            },
            onError: (error) => {
                setMessage(error.graphQLErrors[0].message);
            },
        });
    };

    const checkErrorMessage = (msg) => {
        const errorPrivateTypeChange = 'PRIVATE TYPE CHANGE NOT ALLOWED';
        if (msg.includes(errorPrivateTypeChange)) {
            return t('errorPrivatTypeChange');
        } else {
            return msg;
        }
    };

    const snackBarTranslations = () => {
        return {
            tourEditedSuccessfully: t('tourEditedSuccessfully'),
            toursEditedSuccessfully: t('toursEditedSuccessfully'),
            errorOnATour: t('errorOnATour'),
            errorOnTours: t('errorOnTours'),
            numberOfErrors: t('numberOfErrors'),
            numberOfMarkedTours: t('numberOfMarkedTours'),
        };
    };

    if (selected.length > 0 && messageCollection.length === selected.length) {
        setMessage(createSnackbarMessage(messageCollection, snackBarTranslations()));
        setSelected([]);
        setMessageCollection([]);
        reset();
    }

    const currentLogbook = useOutletContext().logbookId;
    let openTourList = useGetOpenTours(currentLogbook);
    let allDriverData = useContactIsDriverApi(setMessage);
    let driverIds = useGetLogbookDriversApi(currentLogbook);

    useEffect(() => {
        setBadge(openTourList?.length);
    }, [openTourList?.length, setBadge]);

    useEffect(() => {
        if (location.state !== null && location.state.snackbarMessage !== null) {
            setMessage(location.state.snackbarMessage);
            navigate(location.pathname);
            client.refetchQueries({
                include: ['getOpenTours'],
            });
        }
    }, [currentLogbook, navigate, location, message, client, openTourList]);

    const checkBanner = (tour) => {
        let date1 = new Date(Moment(tour.dtBegin).format('MM/DD/YYYY')).getTime();
        let date2 = new Date(Moment().subtract(daysUntilHint, 'days').format('MM/DD/YYYY')).getTime();
        return tour.type !== GetTourTypes().PRIVATE && date1 < date2;
    };

    useEffect(() => {
        if (openTourList) {
            setShowBanner(openTourList.some(checkBanner));
        }
    }, [openTourList]);

    if (!openTourList || !driverIds || !allDriverData) {
        return <LinearProgress />;
    }

    if (openTourList.length === 0) {
        return (
            <>
                <Box sx={{ width: '100%', p: 0, m: 0 }}>
                    <NoTours />
                </Box>
            </>
        );
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = openTourList.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleCheckboxClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const handleRowClick = (event, id) => {
        navigate('/edit/' + id);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - openTourList.length) : 0;

    const getSelectedToursData = () => {
        const selectedToursData = openTourList.filter((tour) => selected.includes(tour.id));
        return selectedToursData;
    };

    const checkWarning = (tour, changeColor) => {
        let date1 = new Date(Moment(tour.dtBegin).format('MM/DD/YYYY')).getTime();
        let date2 = new Date(Moment().subtract(daysUntilHint, 'days').format('MM/DD/YYYY')).getTime();
        if (tour.type !== GetTourTypes().PRIVATE && date1 < date2) {
            return (
                <>
                    <WarningIcon
                        color='error'
                        sx={[
                            changeColor ? { backgroundColor: '#f5f5f5' } : { backgroundColor: '#fff' },
                            {
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                translate: '-50% -50%',
                                zIndex: 1,
                                ':hover': { display: 'none' },
                            },
                        ]}
                    />
                </>
            );
        }
    };

    return (
        <>
            {desktop900 && (
                <>
                    {showBanner && <OpenToursBanner desktop900={desktop900} />}
                    <Box sx={{ width: '100%', p: 0, m: 0 }}>
                        <Card variant='outlined' sx={{ p: 0, m: 0 }}>
                            <CardContent>
                                <OpenToursTableToolbar
                                    selected={selected}
                                    numberOfOpenTours={openTourList.length}
                                    currentLogbook={currentLogbook}
                                    saveTours={saveTours}
                                    textSelected={t('selected')}
                                    getSelectedToursData={getSelectedToursData}
                                    mergeTours={mergeTours}
                                    userAccess={userAccess}
                                />
                                <TableContainer>
                                    <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size={'medium'}>
                                        <OpenToursTableHead
                                            numSelected={selected.length}
                                            order={order}
                                            orderBy={orderBy}
                                            onSelectAllClick={handleSelectAllClick}
                                            onRequestSort={handleRequestSort}
                                            rowCount={openTourList.length}
                                            openTourList={openTourList}
                                            setSelected={setSelected}
                                            daysUntilHint={daysUntilHint}
                                        />
                                        <TableBody onMouseLeave={() => setHoveredRow('')}>
                                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                                rows.slice().sort(getComparator(order, orderBy)) */}
                                            {stableSort(openTourList, getComparator(order, orderBy))
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row, index) => {
                                                    const isItemSelected = isSelected(row.id);
                                                    const labelId = `enhanced-table-checkbox-${index}`;

                                                    return (
                                                        <TableRow
                                                            hover
                                                            role='checkbox'
                                                            aria-checked={isItemSelected}
                                                            tabIndex={-1}
                                                            key={row.id}
                                                            selected={isItemSelected}
                                                            onMouseEnter={() => setHoveredRow(row.id)}
                                                            sx={{
                                                                position: 'relative',
                                                                cursor: 'pointer',
                                                            }}
                                                        >
                                                            <TableCell
                                                                padding='checkbox'
                                                                sx={{
                                                                    width: '80px',
                                                                }}
                                                                onClick={(event) => handleCheckboxClick(event, row.id)}
                                                            >
                                                                <Box position={'relative'} width={'40px'}>
                                                                    <Checkbox
                                                                        color='primary'
                                                                        checked={isItemSelected}
                                                                        inputProps={{
                                                                            'aria-labelledby': labelId,
                                                                        }}
                                                                    />
                                                                    {!isItemSelected && checkWarning(row, hoveredRow === row.id)}
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell onClick={(event) => handleRowClick(event, row.id)} id={labelId} scope='row'>
                                                                {tablet ? (
                                                                    <>
                                                                        {Moment(row.dtBegin).format('DD.MM.yyyy')} <br /> {Moment(row.dtBegin).format('HH:mm:ss')}
                                                                    </>
                                                                ) : (
                                                                    Moment(row.dtBegin).format('DD.MM.yyyy HH:mm:ss')
                                                                )}
                                                            </TableCell>
                                                            <TableCell onClick={(event) => handleRowClick(event, row.id)} align='left'>
                                                                {row.streetBegin ? row.streetBegin + ', ' : ''}
                                                                {tablet && <br />}
                                                                {row.postalBegin} {row.cityBegin}
                                                            </TableCell>
                                                            <TableCell onClick={(event) => handleRowClick(event, row.id)} align='left'>
                                                                {tablet ? (
                                                                    <>
                                                                        {Moment(row.dtEnd).format('DD.MM.yyyy')} <br /> {Moment(row.dtEnd).format('HH:mm:ss')}
                                                                    </>
                                                                ) : (
                                                                    Moment(row.dtEnd).format('DD.MM.yyyy HH:mm:ss')
                                                                )}
                                                            </TableCell>
                                                            <TableCell onClick={(event) => handleRowClick(event, row.id)} align='left'>
                                                                {row.streetEnd ? row.streetEnd + ', ' : ''}
                                                                {tablet && <br />}
                                                                {row.postalEnd} {row.cityEnd}
                                                            </TableCell>
                                                            {hoveredRow === row.id && userAccess < 3 ? (
                                                                <TableCell align='right' sx={{ position: 'relative' }}>
                                                                    <Box
                                                                        sx={{
                                                                            position: 'absolute',
                                                                            paddingRight: '16px',
                                                                            right: '0',
                                                                            top: '50%',
                                                                            transform: 'translateY(-50%)',
                                                                            backgroundColor: '#f5f5f5',
                                                                        }}
                                                                    >
                                                                        <OneClickTourUpdate
                                                                            saveTourOneClick={saveTourOneClick}
                                                                            tourId={row.id}
                                                                            allDriverData={allDriverData}
                                                                            driverIds={driverIds}
                                                                            tourType={row.type}
                                                                        />
                                                                    </Box>
                                                                </TableCell>
                                                            ) : (
                                                                <TableCell onClick={(event) => handleRowClick(event, row.id)} align='right' sx={{ position: 'relative' }}>
                                                                    {row.distance === null ? 'n.a.' : (row.distance / 1000).toFixed(2).replace('.', ',')}
                                                                </TableCell>
                                                            )}
                                                        </TableRow>
                                                    );
                                                })}
                                            {emptyRows > 0 && (
                                                <TableRow
                                                    style={{
                                                        height: 53 * emptyRows,
                                                    }}
                                                >
                                                    <TableCell colSpan={6} />
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component='div'
                                    count={openTourList.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    labelDisplayedRows={(page) => `${page.from} – ${page.to} ${t('of')} ${page.count}`}
                                    labelRowsPerPage={t('rowsPerPage')}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </CardContent>
                        </Card>
                    </Box>
                </>
            )}
            {!desktop900 && (
                <OpenToursTableMobile
                    openTourList={openTourList}
                    desktop900={desktop900}
                    daysUntilHint={daysUntilHint}
                    handleRowClick={handleRowClick}
                    allDriverData={allDriverData}
                    driverIds={driverIds}
                    saveTourOneClick={saveTourOneClick}
                    selected={selected}
                    setSelected={setSelected}
                    handleCheckboxClick={handleCheckboxClick}
                    saveTours={saveTours}
                    getSelectedToursData={getSelectedToursData}
                    mergeTours={mergeTours}
                    showBanner={showBanner}
                    userAccess={userAccess}
                />
            )}
            {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
        </>
    );
}
