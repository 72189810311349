import * as React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { useState } from 'react';
import AccessMenu from './AccessMenu';
import DatePickerSettings from '../../components/ui/DatePickerSettings';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export function AccessContent(props) {
    const location = useLocation();
    const [showExpiryDate, setShowExpiryDate] = useState(false);
    const access = props.access;
    const { t } = useTranslation();
    const limitedPeriodExist = (dtNotBefore, dtNotAfter) => {
        return dtNotBefore !== null || dtNotAfter !== null;
    };

    const checkDate = (dtNotBefore, dtNotAfter) => {
        const currentDate = new Date();
        if ((dtNotBefore !== null && new Date(dtNotBefore) > currentDate) || (dtNotAfter !== null && new Date(dtNotAfter) < currentDate)) {
            return {
                color: '#fc1414',
                marginRight: '8px',
            };
        } else {
            return {
                color: '#16A100',
                marginRight: '8px',
            };
        }
    };

    const getRight = (allowedArray) => {
        let right = t('noAccess');

        if (allowedArray.includes('grant')) {
            right = t('admin');
        } else if (allowedArray.includes('edit')) {
            right = t('editor');
        } else if (allowedArray.includes('view')) {
            right = t('viewer');
        } else if (allowedArray.includes('various')) {
            right = t('various');
        }
        return right;
    };

    const updateLogbookAccess = (changedUserAccess) => {
        if (location.pathname.includes('/admin')) {
            props.changeLogbookAccess(changedUserAccess);
        } else {
            const usersAccessToUpdate = props.logbook.access.map(
                (acc) =>
                    changedUserAccess.find((a) => a.holder === acc.holder) || {
                        holder: acc.holder,
                        allowed: acc.allowed,
                        variant: acc.variant,
                        dtNotAfter: acc.dtNotAfter !== undefined ? acc.dtNotAfter : null,
                        dtNotBefore: acc.dtNotBefore !== undefined ? acc.dtNotBefore : null,
                    }
            );
            if (!props.logbook.access.map((access) => access.holder).includes(changedUserAccess[0].holder)) {
                usersAccessToUpdate.push({
                    holder: changedUserAccess[0].holder,
                    allowed: changedUserAccess[0].allowed,
                    variant: changedUserAccess[0].variant,
                    dtNotAfter: changedUserAccess[0].dtNotAfter !== undefined ? changedUserAccess[0].dtNotAfter : null,
                    dtNotBefore: changedUserAccess[0].dtNotBefore !== undefined ? changedUserAccess[0].dtNotBefore : null,
                });
            }
            props.changeLogbookAccess(usersAccessToUpdate);
        }
    };

    return (
        <>
            <Grid xs={5} md={6} textAlign='left'>
                {access.variant === 'Team' ? `${t('all')} ${t('users')}` : props.getFullnameById(access.holder)}
            </Grid>
            {limitedPeriodExist(access.dtNotBefore, access.dtNotAfter) && (
                <Grid xs={7} md={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <TimerOutlinedIcon sx={checkDate(access.dtNotBefore, access.dtNotAfter)} onClick={() => setShowExpiryDate(!showExpiryDate)} cursor='pointer' />
                    <AccessMenu
                        currentAccess={getRight(access.allowed)}
                        holder={access.holder}
                        variant={access.variant}
                        updateLogbookAccess={updateLogbookAccess}
                        setShowExpiryDate={setShowExpiryDate}
                    />
                </Grid>
            )}
            {!limitedPeriodExist(access.dtNotBefore, access.dtNotAfter) && (
                <Grid xs={7} md={6} textAlign='right'>
                    <AccessMenu
                        currentAccess={getRight(access.allowed)}
                        holder={access.holder}
                        variant={access.variant}
                        updateLogbookAccess={updateLogbookAccess}
                        setShowExpiryDate={setShowExpiryDate}
                    />
                </Grid>
            )}
            {showExpiryDate && (
                <Grid xs={12} md={12} textAlign='right'>
                    <DatePickerSettings
                        access={access}
                        holder={access.holder}
                        variant={access.variant}
                        updateLogbookAccess={updateLogbookAccess}
                        setShowExpiryDate={setShowExpiryDate}
                    />
                </Grid>
            )}
        </>
    );
}
