import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from '@mui/material/Button';
import { Box, Divider, InputBase, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import theme from '../../themes/theme';
import { useLocation, useNavigate } from 'react-router-dom';
import OverviewSvg from '../../img/OverviewSvg';
import { useGetAllAssets } from '../../api/LogbookApi';
import ErrorIcon from '@mui/icons-material/Error';
import { isUserCustomerAdmin } from '../auth/authorization';

export default function LogbookSelection(props) {
    const [searchStarted, setSearchStarted] = useState(false);
    const getLogbookLabel = (logbookId) => {
        return props.logbookList.find((logbook) => logbook.value === logbookId).label;
    };
    const [currentLabel, setCurrentLabel] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [activeLogbooks, setActiveLogbooks] = useState(props.logbookList);
    const { t } = useTranslation();
    const location = useLocation();
    let navigate = useNavigate();
    const assetsData = useRef([]);
    const { getAllAssets } = useGetAllAssets(
        props.logbookList.map((Lgb) => Lgb.assetId),
        assetsData
    );

    useEffect(() => {
        getAllAssets();
    }, [getAllAssets, props.logbookList]);

    useEffect(() => {
        const savedLogbookId = props.lastLogbookId;
        if (location.pathname.includes('/create')) {
            if (currentLabel !== t('newLogbook')) {
                setCurrentLabel(t('newLogbook'));
                props.handleLogbookChange(t('newLogbook'));
            }
        } else if (location.pathname.includes('/admin')) {
            if (currentLabel !== t('overview')) {
                setCurrentLabel(t('overview'));
                props.handleLogbookChange(t('overview'));
            }
        } else if (location.pathname.includes('/unauthorized')) {
            if (currentLabel !== t('selectLogbook')) {
                setCurrentLabel(t('selectLogbook'));
                props.handleLogbookChange(t('selectLogbook'));
            }
        } else if (props.logbookList.length > 0) {
            if (props.logbookList.find((logbook) => logbook.value === props.logbookId) === undefined) {
                setCurrentLabel(savedLogbookId !== null && savedLogbookId !== props.logbookId ? savedLogbookId : props.logbookList[0].label);
                props.handleLogbookChange(savedLogbookId !== null && savedLogbookId !== props.logbookId ? savedLogbookId : props.logbookList[0].value);
            } else {
                setCurrentLabel(props.logbookList.find((logbook) => logbook.value === props.logbookId).label);
            }
        }
    }, [props, currentLabel, location.pathname, t]);

    useEffect(() => {
        setActiveLogbooks(props.logbookList);
    }, [props.logbookList]);

    const handleArrowClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (logbookId) => {
        handleClose();
        setCurrentLabel(getLogbookLabel(logbookId));
        props.handleLogbookChange(logbookId);
    };

    const handleButtonClick = (label) => {
        handleClose();
        setCurrentLabel(label);
        props.handleLogbookChange(label);
    };

    const handleAdminPageLeave = () => {
        handleClose();
        navigate('/');
    };

    const handleClose = () => {
        setAnchorEl(null);
        setActiveLogbooks(props.logbookList);
    };

    const filterLogbooks = (event) => {
        const searchValue = event.target.value.toLowerCase();

        if (searchValue.length === 0) {
            setActiveLogbooks(props.logbookList);
            setSearchStarted(false);
        } else {
            const filteredLogbooks = props.logbookList.filter((logbook) => {
                const labelLowerCase = logbook.label?.toLowerCase();
                const assetId = logbook.assetId;
                const asset = assetsData.current.find((asset) => asset.id === assetId);

                const licensePlate = asset?.child?.licensePlate?.toLowerCase() || '';
                const model = asset?.child?.model?.toLowerCase() || '';

                return labelLowerCase.includes(searchValue) || licensePlate.includes(searchValue) || model.includes(searchValue);
            });

            setActiveLogbooks(filteredLogbooks);
            setSearchStarted(true);
        }
    };
    const renderSecondaryText = (logbook) => {
        const assetId = logbook.assetId;
        const asset = assetsData.current.find((asset) => asset.id === assetId);

        const licensePlate = asset?.child?.licensePlate || 'N/A';
        const model = asset?.child?.model || 'N/A';

        return `${model} (${licensePlate})`;
    };
    return (
        <div>
            <Button
                variant='text'
                color='secondary'
                id='logbook-selection-button'
                aria-controls={open ? 'lock-menu' : undefined}
                aria-haspopup='listbox'
                aria-expanded={open ? 'true' : undefined}
                disableElevation
                onClick={handleArrowClick}
                endIcon={<ArrowDropDownIcon />}
                sx={{ textTransform: 'none' }}
            >
                <Typography variant='h6' maxWidth='224px' whiteSpace='nowrap' overflow='hidden' textOverflow='ellipsis'>
                    {currentLabel}
                </Typography>
            </Button>
            <Menu
                id='lock-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'logbook-selection-button',
                    role: 'listbox',
                    sx: { maxWidth: '364px' },
                }}
            >
                <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='flex-start'
                    border={`solid 1px ${theme.palette.grey[600]}`}
                    borderRadius='4px'
                    sx={{ margin: '8px 16px 8px 16px' }}
                >
                    <SearchIcon sx={{ color: theme.palette.grey[600], margin: '0 8px' }} />
                    <InputBase
                        placeholder={t('searchLogbook')}
                        onChange={(value) => {
                            filterLogbooks(value);
                        }}
                    />
                </Box>
                <Box sx={{ maxHeight: '400px', overflow: 'auto' }}>
                    {activeLogbooks.map((logbookListItem) =>
                        logbookListItem.status !== 'archived' ? (
                            <MenuItem
                                key={logbookListItem.value}
                                sx={{ backgroundColor: props.logbookId === logbookListItem.value ? theme.palette.grey[200] : '' }}
                                onClick={() => handleMenuItemClick(logbookListItem.value)}
                            >
                                <ListItemText
                                    primary={
                                        <Typography
                                            sx={{
                                                color: '#212121',
                                                fontSize: '16px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                        >
                                            {logbookListItem.label}
                                        </Typography>
                                    }
                                    secondary={
                                        searchStarted ? (
                                            <Typography
                                                sx={{
                                                    color: '#757575',
                                                    fontSize: '12px',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                }}
                                            >
                                                {renderSecondaryText(logbookListItem)}
                                            </Typography>
                                        ) : (
                                            ''
                                        )
                                    }
                                />
                                {logbookListItem.status === 'pending' && (
                                    <Tooltip title={t('pendingLogbookMessage')} placement='top-start' enterTouchDelay={0}>
                                        <ListItemIcon sx={{ marginRight: '-16px' }}>
                                            <ErrorIcon color='error' />
                                        </ListItemIcon>
                                    </Tooltip>
                                )}
                            </MenuItem>
                        ) : (
                            <div key={logbookListItem.value}></div>
                        )
                    )}
                    {isUserCustomerAdmin(props.loginUser.adminRoles) && (
                        <MenuItem key={activeLogbooks.length} sx={{ cursor: 'default' }}>
                            <ListItemText
                                primary={
                                    <Typography
                                        sx={{
                                            color: theme.palette.grey[600],
                                            fontSize: '16px',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {t('logbookListInfo')}
                                    </Typography>
                                }
                            />
                        </MenuItem>
                    )}
                </Box>
                <Divider></Divider>
                <Tooltip title={isUserCustomerAdmin(props.loginUser.adminRoles) ? '' : t('missingPermissionsCreateLogbook')} placement='top-start' enterTouchDelay={0}>
                    <span>
                        <Button
                            variant='contained'
                            color='success'
                            disabled={!isUserCustomerAdmin(props.loginUser.adminRoles)}
                            startIcon={<AddIcon sx={{ height: '18px', width: '18px' }} />}
                            sx={{ margin: '16px 0 8px 16px' }}
                            onClick={() => handleButtonClick(t('newLogbook'))}
                        >
                            {t('create')}
                        </Button>
                    </span>
                </Tooltip>
                {isUserCustomerAdmin(props.loginUser.adminRoles) && !location.pathname.includes('/admin') && (
                    <Button
                        variant='outlined'
                        color='secondary'
                        startIcon={<OverviewSvg />}
                        sx={{ margin: '16px 16px 8px 16px', borderColor: theme.palette.secondary.main }}
                        onClick={() => handleButtonClick(t('overview'))}
                    >
                        {t('overview')}
                    </Button>
                )}
                {isUserCustomerAdmin(props.loginUser.adminRoles) && location.pathname.includes('/admin') && (
                    <Button
                        variant='contained'
                        startIcon={<OverviewSvg />}
                        sx={{ margin: '16px 16px 8px 16px', backgroundColor: '#8AD07F' }}
                        onClick={() => handleAdminPageLeave()}
                    >
                        {t('leaveOverview')}
                    </Button>
                )}
            </Menu>
        </div>
    );
}
