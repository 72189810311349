import * as React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, LinearProgress } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import LogbookReasonsListItem from './LogbookReasonsListItem';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useState } from 'react';
import SimpleSnackbar from '../../components/ui/SimpleSnackbar';
import { useEffect } from 'react';
import DialogAddLogbookReason from './DialogAddLogbookReason';
import { useCallback } from 'react';
import { GET_SELECTED_LOGBOOK_REASONS } from '../../api/LogbookApi';
import { compareArrayOfReasons } from '../../components/other/ConfigBasic';

const SAVE_LOGBOOK_REASONS = gql`
    mutation saveLogbookReasons($id: ID!, $reasons: [LogbookReasonInput]) {
        updateLogbook(input: { reasons: $reasons }, id: $id) {
            id
        }
    }
`;

export default function LogbookReasonsList() {
    const { t } = useTranslation();
    const [message, setMessage] = useState('');
    const currentUserId = useOutletContext().loginUser.id;
    const logbookId = useOutletContext().logbookId;
    const [getReasons] = useLazyQuery(GET_SELECTED_LOGBOOK_REASONS);
    const [logbookReasons, setLogbookReasons] = useState(undefined);
    const userAccess = useOutletContext().userAccess;

    const [saveLogbookReason] = useMutation(SAVE_LOGBOOK_REASONS);

    const changeLogbookReasons = useCallback(
        (reasons) => {
            saveLogbookReason({
                variables: {
                    id: logbookId,
                    reasons: reasons,
                },
                onCompleted: () => {
                    setMessage(t('reasonsUpdated'));
                    setLogbookReasons(reasons.sort(compareArrayOfReasons));
                },
                onError: (error) => {
                    setMessage(error.graphQLErrors[0].message);
                },
            });
        },
        [logbookId, saveLogbookReason, t]
    );

    useEffect(() => {
        getReasons({
            variables: {
                id: logbookId,
            },
            onCompleted: (data) => {
                let loadedReasons = data.logbooks[0].reasons.map((reason) => {
                    return {
                        label: reason.label,
                        idPerson: reason.idPerson,
                    };
                });

                const uniqueReasons = loadedReasons.reduce((accumulator, current) => {
                    if (!accumulator.find((item) => item.label === current.label && item.idPerson === current.idPerson)) {
                        accumulator.push(current);
                    }
                    return accumulator;
                }, []);

                if (loadedReasons.length !== uniqueReasons.length) {
                    changeLogbookReasons(uniqueReasons);
                } else {
                    setLogbookReasons(loadedReasons.sort(compareArrayOfReasons));
                }
            },
            onError: (error) => {
                setMessage(error.graphQLErrors[0].message);
            },
        });
    }, [getReasons, logbookId, changeLogbookReasons]);

    if (!logbookReasons) {
        return (
            <>
                <LinearProgress />
                {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
            </>
        );
    }

    const filterReasons = (logbookReasons) => {
        const filteredLogbookReasons = logbookReasons.filter((reason) => reason.idPerson === null || reason.idPerson === currentUserId);
        return filteredLogbookReasons;
    };

    return (
        <>
            <Card variant='outlined'>
                <CardContent>
                    <Grid container xs={12}>
                        <Grid xs={10} textAlign='left'>
                            <h4 style={{ padding: 0, marginBlock: 0 }}>
                                {t('templatesForReason')} {`(${filterReasons(logbookReasons).length})`}
                            </h4>
                        </Grid>
                        <Grid xs={2} textAlign='right' alignSelf='center'>
                            {userAccess < 3 && (
                                <DialogAddLogbookReason
                                    setLogbookReasons={setLogbookReasons}
                                    getReasons={getReasons}
                                    setMessage={setMessage}
                                    changeLogbookReasons={changeLogbookReasons}
                                />
                            )}
                        </Grid>
                        <Grid container xs={12} sx={{ maxHeight: '250px', overflowY: 'scroll' }}>
                            {logbookReasons.length > 0 &&
                                filterReasons(logbookReasons).map((reason) => (
                                    <LogbookReasonsListItem
                                        key={`${reason.label}${reason.idPerson}`}
                                        reason={reason}
                                        setLogbookReasons={setLogbookReasons}
                                        getReasons={getReasons}
                                        setMessage={setMessage}
                                        changeLogbookReasons={changeLogbookReasons}
                                    />
                                ))}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
        </>
    );
}
