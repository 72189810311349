import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import i18n from 'i18next';

/**
 * Create an enum of possible tour types
 * @returns the created enum
 */
export function GetTourTypes() {
    const values = ['BUSINESS', 'COMMUTE', 'OTHER_INCOME', 'PRIVATE', 'TWO_HOUSEHOLDS'];
    const enumObject = {};
    for (const val of values) {
        enumObject[val] = val;
    }
    return Object.freeze(enumObject);
}

/**
 * Create an enum of possible cost categories
 * @returns the created enum
 */
export function GetCostCategories() {
    const values = ['FUEL', 'CLEANING_SERVICES', 'INSPECTION', 'REPAIR', 'LEASING'];
    const enumObject = {};
    for (const val of values) {
        enumObject[val] = val;
    }
    return Object.freeze(enumObject);
}

export function GetLabel(type) {
    let translation = '';
    switch (type) {
        case 'FUEL_DIESEL':
            translation = 'literprice';
            break;
        case 'FUEL_GAS':
            translation = 'literprice';
            break;
        case 'FUEL_ELECTRICITY':
            translation = 'kwh';
            break;
        case 'FUEL_LPG':
            translation = 'literprice';
            break;
        case 'FUEL_CNG':
            translation = 'kgPrice';
            break;
        case 'FUEL_H2':
            translation = 'kgPrice';
            break;
        case 'FUEL_ADBLUE':
            translation = 'literprice';
            break;
        default:
            translation = 'literprice';
            break;
    }
    return translation;
}

/**
 * Create an enum of possible notification channels
 * @returns the created enum
 */
export function GetNotificationChannels() {
    const values = ['email'];
    const enumObject = {};
    for (const val of values) {
        enumObject[val] = val;
    }
    return Object.freeze(enumObject);
}

/**
 * Create an enum of possible notification topics
 * @returns the created enum
 */
export function GetNotificationTopics() {
    const values = ['all', 'openTours', 'privateNotify'];
    const enumObject = {};
    for (const val of values) {
        enumObject[val] = val;
    }
    return Object.freeze(enumObject);
}

export function GetAccessMenu() {
    const { t } = useTranslation();
    const accessMenu = [t('viewer'), t('editor'), t('admin'), t('addExpiryDate'), t('removeAccess')];
    return accessMenu;
}
export function LogBooksRoles() {
    const { t } = useTranslation();
    const accessMenu = [t('chooseRole'), t('viewer'), t('editor'), t('admin')];
    return accessMenu;
}
export function LogBooksRolesExtended() {
    const { t } = useTranslation();
    const accessMenu = [t('viewer'), t('editor'), t('admin')];
    return accessMenu;
}
export function LogBooksTeamRole() {
    const { t } = useTranslation();
    const accessMenu = [t('noAccess'), t('viewer'), t('editor')];
    return accessMenu;
}
export function GetReasonsMenu() {
    const { t } = useTranslation();
    const accessMenu = [t('all'), t('onlyMe')];
    return accessMenu;
}

export function GetEditAddressReasons() {
    const { t } = useTranslation();
    const accessMenu = [t('gpsErorr'), t('other')];
    return accessMenu;
}

export function GetStatusMenu() {
    const { t } = useTranslation();
    const statusMenu = new Map([
        ['all', t('allLogbooks')],
        ['active', t('activeLogbooks')],
        ['pending', t('pendingLogbooks')],
        ['archived', t('archiveLogbooks')],
    ]);
    return statusMenu;
}

export function GetReasonsMenuExtended() {
    const { t } = useTranslation();
    const accessMenu = [t('all'), t('onlyMe'), t('delete')];
    return accessMenu;
}

export function GetAccessStrings() {
    const accessStrings = {
        allowed: {
            view: ['view'],
            edit: ['view', 'edit'],
            grant: ['view', 'edit', 'grant'],
            delete: ['delete'],
        },
        variant: {
            person: 'Person',
        },
    };
    return accessStrings;
}

export const getBreakPoints = () => {
    const breakPoints = {
        desktop900: '(min-width:900px)',
    };
    return breakPoints;
};

/**
 * Get the firstname and lastname from an personobject
 * @param {Object} person, object containing the first name and last name
 * @returns the fullname from the personobject
 */
export const getFullname = (person) => {
    const fullname = `${person?.firstname ? person.firstname + ' ' : ''}${person?.lastname ? person.lastname : ''}`;
    if (fullname.length === 0) {
        return i18n.language.startsWith('en') ? 'Unknown' : 'Unbekannt';
    } else {
        return fullname.trim();
    }
};

/**
 * Get the first letter from the firstname and lastname from an personobject
 * @param {Object} person, object containing the firstname and lastname
 * @returns the initials from the personobject
 */
export const getLetterAvatar = (person) => {
    const initials = `${person?.firstname ? person.firstname.charAt(0) : ''}${person?.lastname ? person.lastname.charAt(0) : ''}`;
    if (initials.length === 0) {
        return '';
    } else {
        return initials;
    }
};

/**
 * Checks, if the logged in user can be set as a selected driver
 * True, set the selected driver
 * @param {String} userUuid, account id from logged in user
 * @param {Array} possibleDriverData, array of possible drivers
 * @param {String} setSelectedDriver, set the selected driver
 * @param {Boolean} setWithId, set the selected driver with the Id otherwise with the fullname
 */
export const searchUserAsDriver = (userUuid, possibleDriverData, setSelectedDriver, setWithId) => {
    let searchUserAsDriverData = possibleDriverData.filter((driver) => driver.loginUuid === userUuid);

    if (searchUserAsDriverData.length > 0) {
        if (setWithId) {
            const initalId = [];
            initalId.push(searchUserAsDriverData[0].id);
            setSelectedDriver(initalId);
        } else {
            const initalName = getFullname(searchUserAsDriverData[0]);
            setSelectedDriver(initalName);
        }
    }
};

export const compareArrayOfReasons = (a, b) => {
    if (a.label < b.label) {
        return -1;
    }
    if (a.label > b.label) {
        return 1;
    }
    return 0;
};

export const getMenuIndex = (path, editAgain, forMobil = false) => {
    let index = null;

    if (path.includes('/edit/') && editAgain !== undefined) {
        path = '/edit';
    }

    if (path.includes('/costs/')) {
        path = '/costs';
    }

    if (forMobil) {
        switch (path) {
            case '/':
                index = 0;
                break;
            case '/status':
                index = 0;
                break;
            case '/admin':
                index = 0;
                break;
            case '/admin/costs':
                index = 1;
                break;
            case '/open':
                index = 1;
                break;
            case '/edited':
                index = 2;
                break;
            case '/costsOverview':
            case '/costs':
                index = 3;
                break;
            case '/settings':
                index = 4;
                break;
            case '/edit':
                if (editAgain) {
                    index = 2;
                } else {
                    index = 1;
                }
                break;
            default:
                index = -1;
                break;
        }
    } else {
        switch (path) {
            case '/open':
                index = 0;
                break;
            case '/':
                index = 0;
                break;
            case '/admin':
                index = 0;
                break;
            case '/admin/costs':
                index = 1;
                break;
            case '/edited':
                index = 1;
                break;
            case '/costsOverview':
            case '/costs':
                index = 2;
                break;
            case '/settings':
                index = 3;
                break;
            case '/edit':
                if (editAgain) {
                    index = 1;
                } else {
                    index = 0;
                }
                break;
            default:
                index = -1;
                break;
        }
    }

    return index;
};

export const getCookie = (cname) => {
    let name = cname + '=';
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
};

export const getNumberWithThousandSeparator = (number, decimalPlaces) => {
    const separator = /\B(?=(\d{3})+(?!\d))/g;
    return number?.toFixed(decimalPlaces).replace(separator, '.');
};

export const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator='.'
            decimalSeparator=','
            valueIsNumericString
        />
    );
});

/**
 * Calculates the distance between two geographic coordinates on Earth.
 * Uses the Haversine formula to calculate the great-circle distance.
 * @param {number} lat1 - Latitude of the first coordinate in degrees.
 * @param {number} lon1 - Longitude of the first coordinate in degrees.
 * @param {number} lat2 - Latitude of the second coordinate in degrees.
 * @param {number} lon2 - Longitude of the second coordinate in degrees.
 * @returns {number} Distance between the two points in kilometers.
 */
export const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const toRadian = (angle) => (Math.PI / 180) * angle;
    const distance = (a, b) => (Math.PI / 180) * (a - b);
    const RADIUS_OF_EARTH_IN_KM = 6371;

    const dLat = distance(lat2, lat1);
    const dLon = distance(lon2, lon1);

    lat1 = toRadian(lat1);
    lat2 = toRadian(lat2);

    const a = Math.sin(dLat / 2) ** 2 + Math.sin(dLon / 2) ** 2 * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return RADIUS_OF_EARTH_IN_KM * c;
};

/**
 * Return an empty String if no one is registered or
 * the contact person id from the registered driver
 * @param {Array} driverRegistration
 * @returns
 */
export const checkDriverRegistration = (driverRegistration) => {
    const activDriver = driverRegistration?.filter((dR) => dR.end === null);

    if (activDriver === undefined || activDriver.length === 0) {
        return '';
    } else {
        return activDriver[0]?.idContactPerson;
    }
};
