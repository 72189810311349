import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputBase,
    useMediaQuery,
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import { useGetLogbooksAccess, useGetPersonsAdminApi, useLogbookAccessUpdater } from '../../api/LogbookApi';
import theme from '../../themes/theme';
import { AccessContent } from '../../routes/Setting/AccessContent';
import { getFullname } from '../other/ConfigBasic';
import Grid from '@mui/material/Unstable_Grid2';

export default function AdminAccessDialog(props) {
    const { open, setOpen, selectedLogbooks, setSelectedLogooks, teamId, setMessage, logbookFilter } = props;
    const { t } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const selectedLogbooksAccess = useGetLogbooksAccess(selectedLogbooks, setMessage, logbookFilter);
    const [accessForDialog, setAccessForDialog] = useState(new Map());
    const [newAccess, setNewAccess] = useState();
    const newPersonsData = useGetPersonsAdminApi();
    const [searchValue, setSearchValue] = useState('');
    const { updateAccessForAllLogbooks, loading } = useLogbookAccessUpdater(setMessage, t);
    
    const handleClose = () => {
        setSelectedLogooks([]);
        setOpen(false)
    };

    const getRight = (allowedArray) => {
        let right = t('noAccess');

        if (allowedArray.includes('grant')) {
            right = t('admin');
        } else if (allowedArray.includes('edit')) {
            right = t('editor');
        } else if (allowedArray.includes('view')) {
            right = t('viewer');
        } else if (allowedArray.includes('various')) {
            right = t('various');
        }
        return right;
    };

    const accessIsIdentical = (accessMap, accessOriginal) => {
        if(getRight(accessMap.get(accessOriginal.holder).allowed) === getRight(accessOriginal.allowed) && accessMap.get(accessOriginal.holder).dtNotAfter === accessOriginal.dtNotAfter && accessMap.get(accessOriginal.holder).dtNotBefore === accessOriginal.dtNotBefore) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Add all other persons with logbook permission with no access to the map
     * @param {Map} access map with user and access 
     * @returns a map with all persons and shows the current access
     */
    const addedPersonsWithoutAccess = (access) => {
        const filteredPersons = newPersonsData
            ?.filter((user) => {
                if (user.zluser && user.zluser.permissions) {
                    return user.zluser.permissions.some((permission) => {
                        return permission.name === '*' || permission.name === 'logbook-cloud';
                    });
                }
                return false;
            })
            .map((user) => ({
                id: user.id,
                firstname: user.firstname,
                lastname: user.lastname,
            }))

        filteredPersons?.forEach((person) => {
            if(!access.has(person.id)) {
                access.set(person.id, {
                    allowed: [],
                    dtNotAfter: null,
                    dtNotBefore: null,
                    holder: person.id,
                    variant: 'Person',
                    name: getFullnameById(person.id),
                })
            }
        })
        return access;
    }

    /**
     * Sorts the map by user and access
     * At first you will see all user sorted alphabetically with access
     * Then you will see all user sorted alphabetically without access
     * @param {Map} unsortedAccess 
     * @returns the sorted map
     */
    const sortAccessForDialog = (unsortedAccess) => {
        const accessSortedByName = new Map([...unsortedAccess.entries()].sort((a, b) => {
            if(b[1].name?.toLowerCase() < a[1].name?.toLowerCase()) {
                return 1;
            }
            if(b[1].name?.toLowerCase() > a[1].name?.toLowerCase()) {
                return -1;
            }
            return 0

        }));
        const accessSortedByRights = new Map([...accessSortedByName.entries()].sort((a, b) => {
            if(b[1].allowed.length > 0 && a[1].allowed.length === 0) {
                return 1;
            }
            if(b[1].allowed.length === 0 && a[1].allowed.length > 0) {
                return -1;
            }
            return 0

        }));
        return accessSortedByRights;
    }

    const createAccessForDialog = (paramLogbooksAccess) => {
        const access = new Map();
        paramLogbooksAccess?.forEach((LogbookAccess, index) => {
            if(index === 0) {
                LogbookAccess.access.forEach((a) => {
                    access.set(a.holder, {
                        allowed: a.allowed,
                        dtNotAfter: a.dtNotAfter,
                        dtNotBefore: a.dtNotBefore,
                        holder: a.holder,
                        variant: a.variant,
                        name: a.variant === 'Team' ? a.variant : getFullnameById(a.holder),
                    })
                })
            } else {
                //More than one logbooks are selected
                const holderInThisLogbook = [];

                LogbookAccess.access.forEach((a) => {
                    if(access.has(a.holder) && accessIsIdentical(access, a)) {
                        //Holder has so far been present in all logbooks, once with the identical access
                    } else {
                        //Various found
                        //Either this holder was not present in the previous logbooks
                        //Or this holder was present in the previous logbooks with a different access
                        access.set(a.holder, {
                            allowed: ['various'],
                            dtNotAfter: null,
                            dtNotBefore: null,
                            holder: a.holder,
                            variant: a.variant,
                            name: a.variant === 'Team' ? a.variant : getFullnameById(a.holder),
                        });
                    }
                    holderInThisLogbook.push(a.holder);
                });

                access.forEach((value, key) => {
                    if(!holderInThisLogbook.includes(key)) {
                        //Various found
                        //A previous holder does not exist in this logbook
                        access.set(key, {
                            allowed: ['various'],
                            dtNotAfter: null,
                            dtNotBefore: null,
                            holder: value.holder,
                            variant: value.variant,
                            name: value.variant === 'Team' ? value.variant : getFullnameById(value.holder),
                        });
                    }
                });
                
            }
        })

        const fullAccess = addedPersonsWithoutAccess(access)

        const sortedAccess = sortAccessForDialog(fullAccess)
        
        setAccessForDialog(sortedAccess);
    }

    useEffect(() => {
        if(selectedLogbooksAccess.length > 0 && accessForDialog.size === 0) {
            createAccessForDialog(selectedLogbooksAccess);
        }
        // eslint-disable-next-line
    }, [selectedLogbooksAccess])

    const getAccessPersons = () => {
        const accessPersons = [];
        accessForDialog.forEach((value, key) => {
            if(key !== teamId) {
                if(searchValue.length > 0 && value.name.toLowerCase().includes(searchValue.toLowerCase())) {
                    accessPersons.push(value);
                } else if(searchValue.length === 0) {
                    accessPersons.push(value);
                }
            }
        })
        return accessPersons;
    };

    const getAccessTeam = () => {
        const accessPersons = [];
        accessForDialog.forEach((value, key) => {
            if(key === teamId) {
                accessPersons.push(value);
            }
        })
        return accessPersons;
    };

    const getFullnameById = (personId) => {
        const person = newPersonsData?.find((person) => person.id === personId);
        return getFullname(person);
    };

    const handleUpdateAccess = (updatedAccess) => {
        const createNewAccess = (currentAccess) => {
            const newEntryArray = [];
            currentAccess.forEach((LgbAccess) => {
                const newEntry = {
                    idLogbook: LgbAccess.idLogbook,
                    access: []
                };
        
                newEntry.access.push(updatedAccess[0]);

                LgbAccess.access.forEach((a) => {
                    if(a.holder !== updatedAccess[0].holder) {
                        newEntry.access.push({
                            holder: a.holder,
                            allowed: a.allowed,
                            variant: a.variant,
                            dtNotAfter: a.dtNotAfter,
                            dtNotBefore: a.dtNotBefore,
                        });   
                    }
                })
    
                newEntryArray.push(newEntry)
            })
            setNewAccess(newEntryArray)
            createAccessForDialog(newEntryArray);
        }

        if(newAccess === undefined) {
            createNewAccess(selectedLogbooksAccess);
        } else {
            createNewAccess(newAccess)
        }
    };

    const accessContainAdmin = (newAccess) => {
        let accessContainAdmin = newAccess.every((obj) => {
            return obj.access.filter((access) => access.allowed.includes('grant')).length > 0;
        });
        return accessContainAdmin
    };

    const handleSaveAccess = async () => {
        if(accessContainAdmin(newAccess)) {
            const result = await updateAccessForAllLogbooks(newAccess);
            if(result) {
                handleClose();
            }
        } else {
            setMessage(t('adminIsRequired'));
        }
    }

    return (
        <>
            <Dialog
                open={open}
                PaperProps={{
                    style: {
                        borderRadius: '28px',
                    },
                }}
                fullScreen={isMobile ? true : false}
            >
                <DialogTitle sx={{ m: 0, p: 2, padding: '24px 24px 16px 24px' }} id='customized-dialog-title'>
                    {t('access')}
                </DialogTitle>
                <DialogContent>
                    <Grid container sx={{padding: 0}}>
                        <Grid xs={10} md={10} textAlign='left'>
                            <h4 style={{ padding: '16px 0 0 0', marginBlock: 0 }}>{t('generalAccess')}</h4>
                        </Grid>
                        {
                            getAccessTeam()?.length === 0 ? (
                                <Grid key={teamId} container sx={{ width: '100%', height: '56px' }}>
                                    <AccessContent
                                        key={teamId}
                                        access={{
                                            holder: teamId,
                                            variant: 'Team',
                                            allowed: [],
                                            dtNotBefore: null,
                                            dtNotAfter: null,
                                        }}
                                        changeLogbookAccess={handleUpdateAccess}
                                        logbook={selectedLogbooks}
                                        getFullnameById={getFullnameById}
                                    />
                                </Grid>
                            ) : (
                                <>
                                    {getAccessTeam()?.map((accessTeam) => (
                                        <Grid key={accessTeam.holder} container sx={{ width: '100%', height: '56px' }}>
                                            <AccessContent
                                                key={accessTeam.holder}
                                                access={accessTeam}
                                                changeLogbookAccess={handleUpdateAccess}
                                                logbook={selectedLogbooks}
                                                getFullnameById={getFullnameById}
                                            />
                                        </Grid>
                                    ))}
                                </>
                            )
                        }
                        <Grid xs={10} md={10} textAlign='left'>
                            <h4 style={{ padding: '16px 0 0 0', marginBlock: 0 }}>{t('individualAccess')}</h4>
                        </Grid>
                        <Box
                            display='flex'
                            alignItems='center'
                            justifyContent='space-between'
                            padding='0 8px'
                            border={`solid 1px ${theme.palette.grey[600]}`}
                            borderRadius='4px'
                            margin='16px 0'
                            width='100%'
                        >
                            <InputBase fullWidth placeholder={t('search')} onChange={(value) => setSearchValue(value?.target?.value)} />
                            <SearchIcon sx={{ color: theme.palette.grey[600] }} />
                        </Box>
                        <Box sx={{  height: isMobile ? `${window.innerHeight - 400}px` : '200px', width: '100%', overflow: 'scroll', overflowX: 'hidden' }}>
                            {
                                getAccessPersons()?.length === 0 ? (
                                    <LinearProgress />
                                ) : (
                                    <>
                                        {getAccessPersons()?.map((accessPerson) => (
                                            <Grid key={accessPerson.holder} container sx={{ width: '100%', height: '56px' }}>
                                                    <AccessContent
                                                        key={accessPerson.holder}
                                                        access={accessPerson}
                                                        changeLogbookAccess={handleUpdateAccess}
                                                        logbook={selectedLogbooks}
                                                        getFullnameById={getFullnameById}
                                                    />
                                                </Grid>
                                            ))
                                        }
                                    </>
                                )
                            }
                        </Box>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ height: '88px', padding: '24px' }}>
                    <Button variant='text' color='secondary' sx={{ height: '40px', marginBottom: '0px !important' }} disabled={loading} onClick={handleClose}>
                        {t('cancel')}
                    </Button>

                    <Button variant='contained' color='success' sx={{ height: '40px', marginBottom: '0px !important' }} disabled={loading} onClick={() => handleSaveAccess()}>
                        {t('save')}
                    </Button>    
                </DialogActions>
            </Dialog>
        </>
    );
}
