import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import LinearProgress from '@mui/material/LinearProgress';
import { useContactIsDriverApi, useGetVehiclesApi, useGetAssetRealOdom } from '../../api/LogbookApi';
import { useTranslation } from 'react-i18next';
import { NumericFormatCustom, getBreakPoints, getFullname } from '../other/ConfigBasic';
import { searchUserAsDriver } from '../other/ConfigBasic';
import { Chip, FormHelperText, Typography, useMediaQuery } from '@mui/material';
import SimpleSnackbar from '../ui/SimpleSnackbar';
import InfoIcon from '@mui/icons-material/Info';
import CreateLogbookActionBar from './CreateLogbookActionBar';
import CreateLogbookAddDriver from './CreateLogbookAddDriver';
import { isUserCustomerAdmin } from '../auth/authorization';

export default function CreateLogbookForm() {
    const desktop900 = useMediaQuery(getBreakPoints().desktop900);
    const { t } = useTranslation();
    const theme = useTheme();
    const [personName, setPersonName] = useState([]);
    const [initialPersonSet, setInitialPersonSet] = useState(false);
    const [vehicle, setVehicle] = useState('');
    const [mileage, setMileage] = useState('');
    const [logbookName, setLogbookName] = useState('');
    let navigate = useNavigate();
    const [validationErrors, setValidationErrors] = useState({
        vehicle: false,
        personName: false,
        logbookName: false,
    });
    const [message, setMessage] = useState('');
    const [realOdom, setRealOdom] = useState(undefined);
    const [checkRealOdom, setCheckRealOdom] = useState(false);

    const callback = (realMileage) => {
        setCheckRealOdom(false);
        setRealOdom(realMileage);
        setMileage(realMileage);
    };

    useGetAssetRealOdom(vehicle, checkRealOdom, callback, setMessage);
    const loginUser = useOutletContext().loginUser;
    const assetsInUse = useOutletContext().logbookList.map((lgb) => lgb.assetId);
    let driverData = useContactIsDriverApi(setMessage);
    let vehiclesData = useGetVehiclesApi(loginUser.custNr);

    useEffect(() => {
        if (driverData && !initialPersonSet) {
            setPersonName(driverData.filter((driver) => driver.loginUuid === loginUser.loginUuid));
            setInitialPersonSet(true);
        }
    }, [driverData, loginUser.loginUuid, initialPersonSet, setInitialPersonSet]);

    if (!driverData || !vehiclesData) {
        return <LinearProgress />;
    }

    driverData.sort((a, b) => {
        if (a.firstname?.toLocaleUpperCase() < b.firstname?.toLocaleUpperCase() || b.firstname === null) {
            return -1;
        } else if (a.firstname?.toLocaleUpperCase() > b.firstname?.toLocaleUpperCase() || a.firstname === null) {
            return 1;
        } else {
            return 0;
        }
    });

    const resetForm = () => {
        setPersonName([]);
        setVehicle('');
        setMileage('');
        setLogbookName('');
        searchUserAsDriver(loginUser.loginUuid, driverData, setPersonName, true);
    };

    const getNewLogbook = () => {
        let idpersons = personName.map((person) => {
            return person.id;
        });
        return {
            custNr: loginUser.custNr,
            idAsset: vehicle,
            mileage: mileage,
            idDrivers: idpersons,
            name: logbookName,
        };
    };

    const handleVehicleChange = (event) => {
        setVehicle(event.target.value);
        setLogbookName(getVehicleName(event.target.value));
        setCheckRealOdom(true);
    };

    const handleMileageChange = (event) => {
        setMileage(parseFloat(event.target.value));
    };

    const handleNameChange = (event) => {
        setLogbookName(event.target.value);
    };

    const getVehicleName = (id) => {
        let vehicle = vehiclesData.filter((vehicle) => vehicle.id === id);
        return vehicle[0].name;
    };

    const formValidation = (setValidationErrors, vehicle, personName, logbookName) => {
        let validationIsSuccessful = true;
        let validationErrors = {
            vehicle: false,
            personName: false,
            logbookName: false,
        };
        if (!vehicle) {
            validationErrors.vehicle = true;
            validationIsSuccessful = false;
        }
        if (personName.length === 0) {
            validationErrors.personName = true;
            validationIsSuccessful = false;
        }
        if (logbookName.trim().length === 0) {
            validationErrors.logbookName = true;
            validationIsSuccessful = false;
        }

        setValidationErrors(validationErrors);

        return validationIsSuccessful;
    };

    const handleToggle = (person) => () => {
        const currentIndex = personName.indexOf(person);
        const newPersonName = [...personName];
        if (currentIndex === -1) {
            newPersonName.push(person);
        } else {
            newPersonName.splice(currentIndex, 1);
        }

        setPersonName(newPersonName);
    };

    const sortPersonList = () => {
        personName.sort((a, b) => {
            if (a.firstname?.toLocaleUpperCase() < b.firstname?.toLocaleUpperCase() || b.firstname === null) {
                return -1;
            } else if (a.firstname?.toLocaleUpperCase() > b.firstname?.toLocaleUpperCase() || a.firstname === null) {
                return 1;
            } else {
                return 0;
            }
        });
    };

    const getDriverChips = () => {
        const maxDriverChips = 5;
        let chips = [];

        sortPersonList();

        personName?.every((person, index) => {
            let returnValue;
            if (index < maxDriverChips) {
                chips.push(<Chip key={person.id} label={getFullname(person)} onDelete={handleToggle(person)} sx={{ marginBottom: '8px', marginRight: '8px' }} />);
                returnValue = true;
            } else if (index === maxDriverChips) {
                chips.push(
                    <Chip
                        key={`additionalDriver${personName.length - maxDriverChips}`}
                        label={`+ ${personName.length - maxDriverChips}`}
                        sx={{ marginBottom: '8px', marginRight: '8px' }}
                    />
                );
                returnValue = false;
            }
            return returnValue;
        });
        return chips;
    };

    if (isUserCustomerAdmin(loginUser.adminRoles)) {
        return (
            <>
                <CreateLogbookActionBar
                    mobil={!desktop900}
                    createLogbook={getNewLogbook()}
                    formValidation={formValidation}
                    setValidationErrors={setValidationErrors}
                    resetForm={resetForm}
                    setMessage={setMessage}
                />
                <Box
                    sx={
                        desktop900
                            ? { height: `${window.innerHeight - 168}px`, overflow: 'auto', position: 'relative', top: '-16px' }
                            : {
                                  position: 'absolute',
                                  left: '0',
                                  top: '64px',
                                  backgroundColor: theme.palette.background.default,
                                  height: 'calc(100vh - 64px)',
                                  width: '100%',
                                  padding: '16px',
                                  zIndex: theme.zIndex.drawer + 2,
                                  overflow: 'auto',
                              }
                    }
                >
                    <Grid item xs={12}>
                        <Typography variant='h4' padding='8px 0 8px 0'>
                            {t('chooseVehicleForLogbook')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth error={validationErrors.vehicle} required={true}>
                            <InputLabel id='demo-simple-select-label'>{t('chooseVehicle')}</InputLabel>
                            <Select labelId='demo-simple-select-label' id='demo-simple-select' value={vehicle} label={t('chooseVehicle')} onChange={handleVehicleChange}>
                                {vehiclesData
                                    .filter((vehicle) => !assetsInUse.includes(vehicle.id))
                                    .map((vehicle) => (
                                        <MenuItem key={vehicle.id} value={vehicle.id}>
                                            {vehicle.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                            {validationErrors.vehicle && <FormHelperText>{t('mandatoryField')}</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h4' padding='24px 0 8px 0'>
                            {t('currentMileageVehicle')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                fullWidth
                                InputProps={{ inputComponent: NumericFormatCustom }}
                                label={t('mileage')}
                                variant='outlined'
                                onChange={handleMileageChange}
                                value={mileage}
                            />
                        </FormControl>
                    </Grid>
                    {realOdom !== undefined && realOdom.length !== 0 && (
                        <Grid xs={12} textAlign='left' sx={{ display: 'flex' }}>
                            <InfoIcon sx={{ alignSelf: 'center', marginRight: '8px' }} /> {t('infoCalibrateOdometer')}
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Typography variant='h4' padding='24px 0 8px 0'>
                            {t('vehicleDriver')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {getDriverChips()}
                        <CreateLogbookAddDriver driverData={driverData} personName={personName} setPersonName={setPersonName} initialPersonSet={initialPersonSet} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h4' padding='24px 0 8px 0'>
                            {t('nameOfLogbook')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth error={validationErrors.logbookName}>
                            <TextField
                                fullWidth
                                error={validationErrors.logbookName}
                                required={true}
                                id=''
                                label='Name'
                                variant='outlined'
                                onChange={handleNameChange}
                                value={logbookName}
                            />
                            {validationErrors.logbookName && <FormHelperText sx={{ paddingBottom: '16px' }}>{t('mandatoryField')}</FormHelperText>}
                        </FormControl>
                    </Grid>
                </Box>
                {message && <SimpleSnackbar message={message} setMessage={setMessage} />}
            </>
        );
    } else {
        return (
            <div>
                <h2>{t('createNewLogbook')}</h2>
                <p>{t('missingPermissionsCreateLogbook')}</p>
                <Button variant='text' color='secondary' onClick={() => navigate(-1)}>
                    {t('back')}
                </Button>
            </div>
        );
    }
}
